import { useState } from "react"

const useLocalStorage = (key, initialValue) => {
  // Get the initial value from localStorage if it exists, otherwise use the provided initialValue
  const storedValue = localStorage.getItem(key);
  const initial = storedValue ? JSON.parse(storedValue) : initialValue;

  // Create a state variable and setter function to manage the local state
  const [value, setValue] = useState(initial);

  // Define a function to update the state and store the new value in localStorage
  const updateValue = (newValue = '') => {
    setValue(newValue);
    localStorage.setItem(key, JSON.stringify(newValue));
  };

  // Return the state value and the update function to use in your component
  return [value, updateValue];
};

export default useLocalStorage;
