import React from "react";
import { Typography } from "antd";
import "../General/general.css";
import "./stakeSetting.css";
import TableStakeSetting from "./component/TableStakeSetting";

const StakingAndWithdrawal = () => {
  return (
    <div className="flex flex-col gap-7">
      <div
        className="custom-box-info flex flex-col rounded-md"
        style={{ border: "1px solid black" }}
      >
        <Typography className="custom-typo-title">STAKE SETTING</Typography>
        <TableStakeSetting />
        <div className="grid grid-cols-2 my-6">
          <div className="flex flex-col gap-4">
            <p className="custom-typo-stake-info">Stake Closing Time</p>
            <div className="flex gap-2">
              <p className="font-bold custom-font-table-data">10:00PM</p>{" "}
              <span className="custom-gmt">GMT</span>{" "}
              <p className="custom-btn-sign-up-code cursor-pointer">Change</p>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <p className="custom-typo-stake-info">Return Crediting Time</p>
            <div className="flex gap-2">
              <p className="font-bold custom-font-table-data">12:00PM</p>{" "}
              <span className="custom-gmt">GMT</span>{" "}
              <p className="custom-btn-sign-up-code cursor-pointer">Change</p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="custom-box-info flex flex-col rounded-md"
        style={{ border: "1px solid black" }}
      >
        <Typography className="custom-typo-title mt-1">
          WITHDRAWAL FEE SETTING
        </Typography>
        <div className="flex flex-col gap-2 my-5">
          <p className="custom-typo-stake-info">Withdrawal Fee</p>
          <div className="flex gap-2">
            <p className="font-bold custom-font-table-data">6 USDT</p>
            <p className="custom-btn-sign-up-code cursor-pointer">Change</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StakingAndWithdrawal;
