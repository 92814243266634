import { Button, Pagination, Table, Typography } from "antd";
import React, { useState } from "react";
import { SelectMenu } from "../../../components/Select";
import { sortOptions } from "../../profile/components/walletComponents/walletItems";
import { userActivitiesCols, userActivitiesCols2, userActivitiesData, userActivitiesData2 } from "./userActivitiesData";

const LayoutLine3 = ({transactionHistory}) => {
  console.log(transactionHistory)
  const [sortTransactionOption, setSortTransactionOption] = useState("");
  const [sortTransactionOption2, setSortTransactionOption2] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPage2, setCurrentPage2] = useState(1);
  const limitItems = 20;
  const totalItems = transactionHistory.length;
  const totalItems2 = userActivitiesData2.length;

  const handleChangeSortTransaction = (value) => {
    setSortTransactionOption(value);
  };

  const handleChangeSortTransaction2 = (value) => {
    setSortTransactionOption2(value);
  };

  const startIndex = (currentPage - 1) * limitItems + 1;
  const endIndex = Math.min(currentPage * limitItems, totalItems);
  const sortedData =
    transactionHistory.length > 0 ? sortTransactionOption === "Latest First"
    ? [...transactionHistory].sort(
        (a, b) => new Date(a.dateTime) - new Date(b.dateTime)
      )
    : [...transactionHistory].sort(
        (a, b) => new Date(b.dateTime) - new Date(a.dateTime)
      ) : [];

  const currentPageData = sortedData.slice(startIndex - 1, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const startIndex2 = (currentPage2 - 1) * limitItems + 1;
  const endIndex2 = Math.min(currentPage2 * limitItems, totalItems2);
  const sortedData2 =
    sortTransactionOption2 === "Latest First"
      ? [...userActivitiesData2].sort(
          (a, b) => new Date(a.dateTime) - new Date(b.dateTime)
        )
      : [...userActivitiesData2].sort(
          (a, b) => new Date(b.dateTime) - new Date(a.dateTime)
        );
  const currentPageData2 = sortedData2.slice(startIndex2 - 1, endIndex2);

  const handlePageChange2 = (page) => {
    setCurrentPage2(page);
  };

  return (
    <div className="flex gap-10 justify-between my-10">
      <div
        className="border-none p-3 rounded-md"
        style={{
          backgroundColor: "#ededed",
          color: "#252525",
          width: "600px",
        }}
      >
        <div className="flex justify-between items-center">
          <Typography className="font-bold" style={{ fontSize: "13px" }}>
            Transaction History
          </Typography>
          {/* <div className="flex gap-2">
            <p className="pt-1" style={{ fontSize: "13px" }}>
              Sort by:
            </p>
            <SelectMenu
              defaultValue={sortOptions[0].value}
              handleChange={handleChangeSortTransaction}
              options={sortOptions}
              width="w-32"
            />
          </div> */}
        </div>
        <Table
          style={{
            borderCollapse: "separate",
            tableLayout: "fixed",
            width: "100%",
            marginTop: "20px",
          }}
          columns={userActivitiesCols}
          dataSource={currentPageData}
          pagination={false}
          size="small"
          bordered={false}
          className="user-activities"
        />
        <div className="mt-3 font-bold ml-1" style={{ fontSize: "11px" }}>
          Showing {startIndex} - {endIndex} of {totalItems} transactions
        </div>
        <div className="flex justify-center mt-3">
          <Button type="text" onClick={() => setCurrentPage(1)}>
            <p style={{ fontSize: "13px" }}>First</p>
          </Button>
          <Pagination
            current={currentPage}
            pageSize={limitItems}
            total={totalItems}
            onChange={handlePageChange}
            style={{ fontSize: "13px" }}
            className="user-activities-pagination"
          />
          <Button
            type="text"
            onClick={() => setCurrentPage(Math.ceil(totalItems / limitItems))}
          >
            <p style={{ fontSize: "13px" }}>Last</p>
          </Button>
        </div>
      </div>
      {/* <div
        className="border-none p-3"
        style={{
          backgroundColor: "#ededed",
          color: "#252525",
          width: "465px",
        }}
      >
        <div className="flex justify-between items-center">
          <Typography className="font-bold" style={{ fontSize: "13px" }}>
            User Activities
          </Typography>
          <div className="flex gap-2">
            <p className="pt-1" style={{ fontSize: "13px" }}>
              Sort by:
            </p>
            <SelectMenu
              defaultValue={sortOptions[0].value}
              handleChange={handleChangeSortTransaction2}
              options={sortOptions}
              width="w-32"
            />
          </div>
        </div>
        <Table
          style={{
            borderCollapse: "separate",
            tableLayout: "fixed",
            width: "100%",
            height: "730px",
            marginTop: "20px",
          }}
          columns={userActivitiesCols2}
          dataSource={currentPageData2}
          pagination={false}
          size="small"
          bordered={false}
          className="user-activities"
        />
        <div className="mt-3 font-bold ml-1" style={{ fontSize: "11px" }}>
          Showing {startIndex2} - {endIndex2} of {totalItems2} transactions
        </div>
        <div className="flex justify-center mt-3">
          <Button type="text" onClick={() => setCurrentPage2(1)}>
            <p style={{ fontSize: "13px" }}>First</p>
          </Button>
          <Pagination
            current={currentPage2}
            pageSize={limitItems}
            total={totalItems2}
            onChange={handlePageChange2}
            style={{ fontSize: "13px" }}
            className="user-activities-pagination"
          />
          <Button
            type="text"
            onClick={() => setCurrentPage2(Math.ceil(totalItems2 / limitItems))}
          >
            <p style={{ fontSize: "13px" }}>Last</p>
          </Button>
        </div>
      </div> */}
    </div>
  );
};

export default LayoutLine3;
