import { CopyOutlined } from "@ant-design/icons";
import { Button, Input, InputNumber, Modal, Typography, message } from "antd";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import UserApi from "../../api/user";
import WalletApi from "../../api/wallet";
import Loading from "../Loading";
import "./deposit.css";

const Deposit = ({ isModalOpen, setIsModalOpen, refetch }) => {
  const [accountInfo, setAccountInfo] = useState({});
  const [depositAmount, setDepositAmount] = useState(0);
  const [availableBalance, setAvailableBalance] = useState(0);
  const { isLoading: accountInfoLoading } = useQuery(
    "getSelf",
    UserApi.getSelf,
    {
      onSuccess: (res) => {
        setAccountInfo(res.data);
        setAvailableBalance(res.data.availableBalance);
      },
      onError: (error) => {
        message.error("Error while Getting available balance");
      },
    }
  );

  const { mutate: depositWallet, isLoading: depositWalletLoading } =
    useMutation(WalletApi.deposit, {
      onSuccess: (res) => {
        message.success("Deposit success");
        refetch();
        setAvailableBalance(availableBalance + depositAmount);
      },
      onError: (error) => {
        message.error("Error while Deposit");
      },
    });

  const handleDeposit = () => {
    depositWallet(depositAmount);
  };

  return (
    <Modal
      open={isModalOpen}
      onOk={() => setIsModalOpen(false)}
      onCancel={() => setIsModalOpen(false)}
      footer={null}
      closeIcon={false}
      className="text-white"
    >
      {accountInfoLoading ? (
        <Loading />
      ) : (
        <div className="flex flex-col gap-3">
          <>
            <Typography className="font-semibold text-white">Tron USDT Address (TRC20): </Typography>
            <div className="flex gap-3 justify-start items-center">
              <Input value={accountInfo.appWallet} />
              <CopyOutlined
                onClick={() => {
                  navigator.clipboard.writeText(accountInfo?.appWallet);
                  message.info("Copied to clipboard");
                }}
              />
            </div>
          </>
          <>
            <Typography className="font-semibold text-white">Amount: </Typography>
            <Input />
          </>
          <div className="flex gap-1">
            <Typography className="font-semibold text-white">
              Available Balance:{" "}
            </Typography>
            {availableBalance}
          </div>

          <div className="flex gap-2">
            <InputNumber
              min={0}
              value={depositAmount}
              onChange={(value) => setDepositAmount(value)}
            />
            <Button
              onClick={handleDeposit}
              loading={depositWalletLoading}
              className="w-32"
            >
              Submit
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default Deposit;
