import { message } from "antd";
import React, { useState } from "react";
import { useQuery } from "react-query";
import UserApi from "../../../../api/user";
import Deposit from "../../../../components/Deposit";
import Withdraw from "../../../../components/Withdraw";

const BalanceLayout = () => {
  const [isDepositModalOpen, setIsDepositModalOpen] = useState(false);
  const [isWithdrawModelOpen, setIsWithdrawModelOpen] = useState(false);
  const [balance, setBalance] = useState({});

  const {
    isLoading: availablProfileWalletLoading,
    refetch: refetchProfileWalletBalance,
  } = useQuery("profileWalletBalance", UserApi.getSelf, {
    onSuccess: (res) => {
      setBalance(res.data);
    },
    onError: (error) => {
      message.error("Error while Getting profile wallet balance");
    },
  });

  return (
    // <div className="grid grid-cols-2 lg:grid-cols-8 gap-2">
    //   <div className="grid grid-rows-2">
    //     <p className="font-bold text-black md:text-xs">Today's Earning</p>
    //     <p className="text-xl md:text-base">
    //       {balance.availableBalance} USDT
    //     </p>
    //   </div>
    //   <div className="grid grid-rows-2">
    //     <p className="font-bold text-black md:text-xs">Total Earning</p>
    //     <p className="text-xl md:text-base">
    //       {balance.availableBalance} USDT
    //     </p>
    //   </div>
    //   <div className="grid grid-rows-2">
    //     <p className="font-bold text-black md:text-xs">Available Balance</p>
    //     <p className="text-xl md:text-base">
    //       {balance.stakedBalance} USDT
    //     </p>
    //   </div>
    //   <div className="grid grid-rows-2">
    //     <p className="font-bold text-black md:text-xs">Staked Balance</p>
    //     <p className="text-xl md:text-base">
    //       {balance.holdingBalance} USDT
    //     </p>
    //   </div>
    //   <div className="grid grid-rows-2">
    //     <p className="font-bold text-black md:text-xs">Total Balance</p>
    //     <p className="text-xl md:text-base">
    //       {balance.totalBalance} USDT
    //     </p>
    //   </div>
    //   <div className="mt-2">
    //     <button
    //       className="border border-transparent bg-slate-700 text-white text-base tracking-widest w-32 h-8 md:w-24 hover:bg-slate-500"
    //       onClick={() => setIsDepositModalOpen(true)}
    //     >
    //       Deposit
    //     </button>
    //   </div>
    //   <div className="mt-2">
    //     <button
    //       className="border border-transparent bg-slate-700 text-white text-base tracking-widest w-32 h-8 md:w-24 hover:bg-slate-500"
    //       onClick={() => setIsWithdrawModelOpen(true)}
    //     >
    //       Withdraw
    //     </button>
    //   </div>
    //   <Deposit
    //     isModalOpen={isDepositModalOpen}
    //     setIsModalOpen={setIsDepositModalOpen}
    //     refetch={refetchProfileWalletBalance}
    //   />
    //   <Withdraw
    //     isModalOpen={isWithdrawModelOpen}
    //     setIsModalOpen={setIsWithdrawModelOpen}
    //     refetch={refetchProfileWalletBalance}
    //   />
    // </div>
    <div className="flex justify-around flex-wrap gap-4" style={{color: 'rgb(37,37,37)'}}>
      <div className="flex flex-col gap-2 text-center">
        <p className="font-bold md:text-xs">Today's Earning</p>
        <p className="text-xl md:text-base">{balance.availableBalance} USDT</p>
      </div>
      <div className="flex flex-col gap-2 text-center">
        <p className="font-bold md:text-xs">Total Earning</p>
        <p className="text-xl md:text-base">{balance.availableBalance} USDT</p>
      </div>
      <div className="flex flex-col gap-2 text-center">
        <p className="font-bold md:text-xs">Available Balance</p>
        <p className="text-xl md:text-base">{balance.stakedBalance} USDT</p>
      </div>
      <div className="flex flex-col gap-2 text-center">
        <p className="font-bold md:text-xs">Staked Balance</p>
        <p className="text-xl md:text-base">{balance.holdingBalance} USDT</p>
      </div>
      <div className="flex flex-col gap-2 text-center">
        <p className="font-bold md:text-xs">Total Balance</p>
        <p className="text-xl md:text-base">{balance.totalBalance} USDT</p>
      </div>
      <div className="flex gap-4 items-center">
        <button
          className="text-base tracking-widest px-6 py-1 custom-waller-button"
          onClick={() => setIsDepositModalOpen(true)}
        >
          Deposit
        </button>
        <button
          className="text-base tracking-widest px-5 py-1 custom-waller-button"
          onClick={() => setIsWithdrawModelOpen(true)}
        >
          Withdraw
        </button>
      </div>
      <Deposit
        isModalOpen={isDepositModalOpen}
        setIsModalOpen={setIsDepositModalOpen}
        refetch={refetchProfileWalletBalance}
      />
      <Withdraw
        isModalOpen={isWithdrawModelOpen}
        setIsModalOpen={setIsWithdrawModelOpen}
        refetch={refetchProfileWalletBalance}
      />
    </div>
  );
};

export default BalanceLayout;
