import { message } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import AuthApi from "../../../../api/auth";
import UserInfoApi from "../../../../api/profile/user-info";
import UserApi from "../../../../api/user";
import useLocalStorage from "../../../../hooks/localStorage.hook";
import ChangeEmail from "./ChangeEmail";
import ChangePass from "./ChangePass";
import ChangeWithdrawalPin from "./ChangeWithdrawalPin";
import ProfileIcon from "./ProfileIcon";
import "./info.css";

const InfoWidget1 = () => {
  const navigate = useNavigate();
  const [userLoggedIn] = useLocalStorage("user", null);
  const [messageApi] = message.useMessage();
  if (!userLoggedIn) {
    alert("Please login first");
    window.location.replace("/");
  }

  const [user, setUser] = useState({});
  const { isLoading: accountInfoLoading } = useQuery(
    "getSelf",
    UserApi.getSelf,
    {
      onSuccess: (res) => {
        setUser(res.data);
      },
      onError: (error) => {
        message.error("Error while Getting available balance");
      },
    }
  );

  const { mutate: sendEmailVerification } = useMutation(
    AuthApi.sendEmailVerification,
    {
      onSuccess: (res) => {
        messageApi.open({
          type: "success",
          content: "Email verification sent, please check your email",
        });
      },
      onError: (error) => {
        messageApi.open({
          type: "error",
          content: "Error while sending email verification",
        });
      },
    }
  );

  const [openEmail, setOpenEmail] = useState(false);
  const [openPassword, setOpenPassword] = useState(false);
  const [openWithdrawalPin, setOpenWithdrawalPin] = useState(false);
  const showModalChangeEmail = () => {
    setOpenEmail(true);
  };
  const showModalChangePassword = () => {
    setOpenPassword(true);
  };
  const showModalChangeWithdrawalPin = () => {
    setOpenWithdrawalPin(true);
  };
  // const [newPass, setNewPass] = useState("");
  // const [newEmail, setNewEmail] = useState("");
  // const [newPin, setNewPin] = useState("");
  const [directConsultantName, setDirectConsultantName] = useState("");

  // const { mutate: putUserInfo, isLoading: putUserInfoLoading } = useMutation(
  //   async (userData) => {
  //     return await UserInfoApi.putUserInfo(user?.id, userData);
  //   },
  //   {
  //     onSuccess: (res) => {
  //       message.success("Update Info Success");
  //     },
  //     onError: (error) => {
  //       messageApi.open({
  //         type: "error",
  //         content: "Error while update info",
  //       });
  //     },
  //   }
  // );

  useEffect(() => {
    if (!!user?.tierRank1UserId) {
      UserApi.getUser(user.tierRank1UserId)
        .then((res) => {
          setDirectConsultantName(res.data.name);
        })
        .catch((err) => {
          message.error("Error while Getting User");
        });
    }
  }, [user?.tierRank1UserId]);

  // const handleOk = () => {
  //   if (newPass === "" && newEmail === "" && newPin === "") {
  //     messageApi.open({
  //       type: "warning",
  //       content: "Please fill at least one field",
  //     });
  //   } else {
  //     putUserInfo(user?.id);
  //   }
  // };

  return (
    // <div className="custom-layout">
    //   {contextHolder}
    //   <div className="space-y-1 w-64">
    //     <Typography className="font-bold text-lg">
    //       Your Personal Details
    //     </Typography>
    //     <Typography className="text-base mt-2">{user?.name}</Typography>
    //     <Typography style={{ color: "#808080" }}>
    //       Account ID: {user?.id}
    //     </Typography>
    //     <Typography style={{ color: "#808080" }}>
    //       Email: {user?.email}
    //       <Button
    //         disabled={user.confirmedEmail == true}
    //         className={`bg-blue-500 text-white font-bold rounded ${user.confirmedEmail ? "opacity-50 cursor-not-allowed" : ""}`}
    //         onClick={() => {
    //           sendEmailVerification();
    //         }}>{user.confirmedEmail ? "Email Verified" : "Verify Email"}
    //       </Button>
    //     </Typography>
    //     <Typography
    //       style={{ color: "#5aa3e7" }}
    //       className="text-xs cursor-pointer"
    //       onClick={showModal}
    //     >
    //       Change email, password & withdrawal pin
    //     </Typography>
    //   </div>
    //   <div className="space-y-1 flex flex-col gap-2">
    //     <Typography className="font-bold text-lg">
    //       Your Direct Consultant
    //     </Typography>
    //     {directConsultantName && (
    //       <div className="flex flex-row gap-3">
    //         {" "}
    //         <Typography className="text-base mt-2">
    //           {directConsultantName} {""}
    //           <div
    //             className="inline border border-slate-400 bg-slate-200 px-2 rounded-full"
    //             style={{ color: "#397ab6", fontSize: "14px" }}
    //           >
    //             {/* {rank} */}
    //           </div>
    //         </Typography>
    //       </div>
    //     )}

    //     <p className="font-xs">
    //       Telegram:{" "}
    //       <a
    //         href="https://t.me/candy.lim/7493"
    //         className="inline"
    //         style={{ color: "#5aa3e7" }}
    //       >
    //         https://t.me/candy.lim/7493
    //       </a>
    //     </p>
    //   </div>
    // </div>
    <div>
      <div
        className="flex flex-row justify-between mobile-style-widget1"
        style={{ color: "rgb(37,37,37)" }}
      >
        <div className="flex flex-col gap-5">
          <div className="flex gap-2">
            <ProfileIcon name={user?.name} />
            <div className="flex flex-col">
              <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                {user?.name}
              </p>
              <p style={{ fontSize: "12px" }}>(User ID: {user?.userId})</p>
            </div>
          </div>
          <p style={{ fontSize: "12px", fontWeight: "bold" }}>
            Joined {dayjs(user?.createdAt).format("DD MMM YYYY")}
          </p>
        </div>
        <div class="grid grid-cols-3 gap-4">
          <div className="col-span-2">
            <div className="flex flex-col gap-5">
              <p style={{ fontSize: "15px", fontWeight: "bold" }}>
                Email: <span className="font-normal pl-1">{user?.email}</span>
              </p>
              <p style={{ fontSize: "15px", fontWeight: "bold" }}>
                Password: <span className="font-normal pl-1">•••••••••</span>
              </p>
              <p style={{ fontSize: "15px", fontWeight: "bold" }}>
                Withdrawal pin:{" "}
                <span className="font-normal pl-1">•••••••••</span>
              </p>
            </div>
          </div>
          <div className="col-span-1">
            <div className="flex flex-col gap-5">
              <p
                style={{
                  fontSize: "11px",
                  color: "#0A00FF",
                  cursor: "pointer",
                }}
                className="pt-1"
                onClick={showModalChangeEmail}
              >
                Change
              </p>
              <p
                style={{
                  fontSize: "11px",
                  color: "#0A00FF",
                  cursor: "pointer",
                }}
                className="pt-1"
                onClick={showModalChangePassword}
              >
                Change
              </p>
              <p
                style={{
                  fontSize: "11px",
                  color: "#0A00FF",
                  cursor: "pointer",
                }}
                className="pt-1"
                onClick={showModalChangeWithdrawalPin}
              >
                Change
              </p>
            </div>
          </div>
        </div>
      </div>
      <ChangeEmail
        openEmail={openEmail}
        setOpenEmail={setOpenEmail}
        user={user}
      />
      <ChangePass
        openPassword={openPassword}
        setOpenPassword={setOpenPassword}
        user={user}
      />
      <ChangeWithdrawalPin
        openWithdrawalPin={openWithdrawalPin}
        setOpenWithdrawalPin={setOpenWithdrawalPin}
        user={user}
      />
      {/* <Modal
        title="Change email and password"
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        className="info-modal"
        footer={null}
      >
        <div className="flex flex-col gap-2">
          <Typography className="font-bold text-sm">New Email</Typography>
          <div className="flex gap-2">
            <Input
              placeholder="Enter your new email"
              className="info-input"
              type="email"
              onChange={(e) => {
                setNewEmail(e.target.value);
              }}
            />
            <Button key="submit" onClick={handleOk}>
              Save
            </Button>
          </div>
          {error && (
            <Typography className="text-sm italic text-red-600">
              Email format is wrong
            </Typography>
          )} 

          <Typography className="font-bold text-sm">New Password</Typography>
          <div className="flex gap-2">
            <Input.Password
              placeholder="Enter your new password"
              className="info-input"
              onChange={(e) => setNewPass(e.target.value)}
            />
            <Button key="submit" onClick={handleOk}>
              Save
            </Button>
          </div>

          <Typography className="font-bold text-sm">
            New Withdraw Pin
          </Typography>
          <div className="flex gap-2">
            <Input
              placeholder="Enter your new withdraw pin"
              className="info-input"
              onChange={(e) => setNewPin(e.target.value)}
            />
            <Button key="submit" onClick={handleOk}>
              Save
            </Button>
          </div>
        </div>
      </Modal> */}
    </div>
  );
};

export default InfoWidget1;
