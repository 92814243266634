import axios from "axios";
axios.defaults.baseURL =
  process.env.REACT_APP_PUBLIC_API_URL;

export default class AdminUserInfoApi {
  static async getUserInfo(userId) {
    const res = await axios.get(`/admin/userInfo/${userId}`);
    return res;
  }
  static async getUserInfoByUserId(userId) {
    const res = await axios.get(`/admin/userInfo/userId/${userId}`);
    return res;
  }

  static async getTransactionHistory(userId) {
    const res = await axios.get(`/admin/userInfo/${userId}/transactionHistory`);
    return res;
  }

  static async adminGetAllUserInfoPage(page) {
    const res = await axios.get(`/admin/userInfo/all/${page}`);
    return res;
  }

  static async adminGetAllUserInfoCount() {
    const res = await axios.get(`/admin/userInfo/all/count`);
    return res;
  }
}
