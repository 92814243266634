import axios from "axios";
axios.defaults.baseURL =
  process.env.REACT_APP_PUBLIC_API_URL;

export default class AdminACcountAndSecurityApi {
  static async getCluster() {
    const res = await axios.get("/admin/cluster");
    return res;
  }

  static async postCluster(clusterData) {
    const res = await axios.post("/admin/cluster", clusterData);
    return res;
  }

  static async updateCluster(id, clusterData) {
    const res = await axios.put(`/admin/cluster/${id}`, clusterData);
    return res;
  }
}
