import { Routes, Route, BrowserRouter } from "react-router-dom";

import "./App.css";

import HomePage from "./pages/homepage";
import Profile from "./pages/profile";
import Stake from "./pages/stake";
import UserInfo from "./pages/user_info";
import UserWallet from "./pages/user_wallet";
import Setting from "./pages/setting";
import UserActivities from "./pages/user_activities";
import AdminDashboard from "./pages/admin_dashboard";
import ProtectedRoutes from "./store/ProtectedRoutes";
import ResetPassword from "./pages/reset_password";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage title={"Homepage"} />} />
        <Route
          path="?referralCode=referralCode"
          element={<HomePage title={"Homepage"} />}
        />
        <Route path="/stake" element={<Stake title={"Stake"} />} />
        <Route path="/reset-password" element={<ResetPassword title={"Reset Password"} />} />
        <Route element={<ProtectedRoutes />}>
          {" "}
          <Route path="/profile" element={<Profile title={"Profile"} />} />
          <Route
            path="/admin/dashboard"
            element={<AdminDashboard title={"Dashboard"} />}
          />
          <Route
            path="/admin/user_info/:id"
            element={<UserActivities title={"User Info"} />}
          />
          <Route
            path="/admin/user_activities"
            element={<UserInfo title={"User Activities"} />}
          />
          <Route
            path="/admin/user_wallet"
            element={<UserWallet title={"User Wallet"} />}
          />
          <Route
            path="/admin/setting"
            element={<Setting title={"Setting"} />}
          />
        </Route>

        <Route path="*" element={<h1>Not Found</h1>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
