import React from "react";
import InputStakeSetting from "./InputPeriod";
import SelectLockupDuration from "./SelectLockupDuration";
import SelectReturnFrequency from "./SelectReturnFrequency";
import InputPeriod from "./InputPeriod";
import InputApr from "./InputApr";

const StakeSettingLayout = ({ control, data }) => {
  const duration = [];
  for (let i = 0; i <= 30; i++) {
    duration.push({
      label: i,
      value: i,
    });
  }

  return (
    <div className="my-8">
      <p style={{ fontSize: "15px", fontWeight: 600 }}>Stake Setting</p>
      <div className="flex gap-6 mt-2">
        <div className="flex flex-col gap-2">
          <p style={{ fontSize: "11px" }}>Period</p>
          <InputPeriod name="periodLine1" control={control} />
          <InputPeriod name="periodLine2" control={control} />
          <InputPeriod name="periodLine3" control={control} />
          <InputPeriod name="periodLine4" control={control} />
          <InputPeriod name="periodLine5" control={control} />
          <InputPeriod name="periodLine6" control={control} />
          <InputPeriod name="periodLine7" control={control} />
          <InputPeriod name="periodLine8" control={control} />
        </div>
        <div className="flex flex-col gap-2">
          <p style={{ fontSize: "11px" }}>APR</p>
          <InputApr name="aprLine1" control={control} />
          <InputApr name="aprLine2" control={control} />
          <InputApr name="aprLine3" control={control} />
          <InputApr name="aprLine4" control={control} />
          <InputApr name="aprLine5" control={control} />
          <InputApr name="aprLine6" control={control} />
          <InputApr name="aprLine7" control={control} />
          <InputApr name="aprLine8" control={control} />
        </div>
        <div className="flex flex-col gap-2">
          <p style={{ fontSize: "11px" }}>Lockup Duration</p>
          <SelectLockupDuration
            name="lockupDurationLine1"
            control={control}
            sortOptions={duration}
            data={data}
          />
          <SelectLockupDuration
            name="lockupDurationLine2"
            control={control}
            sortOptions={duration}
            data={data}
          />
          <SelectLockupDuration
            name="lockupDurationLine3"
            control={control}
            sortOptions={duration}
            data={data}
          />
          <SelectLockupDuration
            name="lockupDurationLine4"
            control={control}
            sortOptions={duration}
            data={data}
          />
          <SelectLockupDuration
            name="lockupDurationLine5"
            control={control}
            sortOptions={duration}
            data={data}
          />
          <SelectLockupDuration
            name="lockupDurationLine6"
            control={control}
            sortOptions={duration}
            data={data}
          />
          <SelectLockupDuration
            name="lockupDurationLine7"
            control={control}
            sortOptions={duration}
            data={data}
          />
          <SelectLockupDuration
            name="lockupDurationLine8"
            control={control}
            sortOptions={duration}
            data={data}
          />
        </div>
        <div className="flex flex-col gap-2">
          <p style={{ fontSize: "11px" }}>Return Frequency</p>
          <SelectReturnFrequency
            name="returnFrequencyLine1"
            control={control}
            sortOptions={duration}
            data={data}
          />
          <SelectReturnFrequency
            name="returnFrequencyLine2"
            control={control}
            sortOptions={duration}
            data={data}
          />
          <SelectReturnFrequency
            name="returnFrequencyLine3"
            control={control}
            sortOptions={duration}
            data={data}
          />
          <SelectReturnFrequency
            name="returnFrequencyLine4"
            control={control}
            sortOptions={duration}
            data={data}
          />
          <SelectReturnFrequency
            name="returnFrequencyLine5"
            control={control}
            sortOptions={duration}
            data={data}
          />
          <SelectReturnFrequency
            name="returnFrequencyLine6"
            control={control}
            sortOptions={duration}
            data={data}
          />
          <SelectReturnFrequency
            name="returnFrequencyLine7"
            control={control}
            sortOptions={duration}
            data={data}
          />
          <SelectReturnFrequency
            name="returnFrequencyLine8"
            control={control}
            sortOptions={duration}
            data={data}
          />
        </div>
      </div>
    </div>
  );
};

export default StakeSettingLayout;
