import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import { Provider } from "react-redux";
import store, { persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import Loading from "./components/Loading";
import { QueryClient, QueryClientProvider } from "react-query";
import { RecoilRoot } from "recoil";

const queryClient = new QueryClient();

const AppStorageWrapper = ({ children }) => {
  return (
    <RecoilRoot>
      <Provider store={store}>
        <PersistGate loading={<Loading />} persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            {children}
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </RecoilRoot>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AppStorageWrapper>
    <App />
  </AppStorageWrapper>
);
