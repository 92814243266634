import { ErrorMessage } from "@hookform/error-message";
import { Modal, Typography } from "antd";
import React from "react";
import InputModal from "./InputModal";

const ForgotPassPopup = ({
  isModalOpenLogin,
  handleCancelLogin,
  handleSubmit,
  onLoginSubmit,
  control,
  errors,
  setIsModalOpenLogin,
  setIsModalOpenRegister,
  isModalOpenForgotPass,
  setIsModalOpenForgotPass,
  onForgotPasswordSubmit,
  handleCancelForgotPass,
}) => {
  return (
    <Modal
      open={isModalOpenForgotPass}
      onCancel={handleCancelForgotPass}
      closeIcon={false}
      footer={null}
      style={{ width: "460px" }}
    >
      <div>
        <form
          onSubmit={handleSubmit(onForgotPasswordSubmit)}
          className="flex flex-col p-5"
        >
          <Typography className="text-4xl font-bold m-auto pb-3 mb-5">
            Reset Your Password
          </Typography>
          <InputModal
            control={control}
            name="email"
            placeholder="Email"
            type="email"
          />
          <ErrorMessage
            errors={errors}
            name="password"
            render={({ message }) => (
              <p className="text-red-500 text-xs italic mb-2">{message}</p>
            )}
          />
          <div className="flex justify-end pb-3">
            <Typography
              className="cursor-pointer italic text-blue-600"
              style={{fontSize: "12px"}}
              onClick={() => {
                setIsModalOpenLogin(true);
                setIsModalOpenRegister(false);
                setIsModalOpenForgotPass(false);
              }}
            >
              Sign In
            </Typography>
          </div>

          <button
            type="submit"
            style={{
              height: "36px",
              backgroundColor: "#2c2c2c",
              transition: "all 0.2s ease, visibility 0s",
              fontSize: "15px",
            }}
            className="col-start-5 font-semibold text-white rounded-lg m-auto w-full"
          >
            SUBMIT
          </button>
        </form>
      </div>
    </Modal>
  );
};

export default ForgotPassPopup;
