export const getAuthTokens = () => {
  const tokens = localStorage.getItem("authToken");
  if (!tokens) {
    return null;
  }
  const { accessToken, refreshToken } = JSON.parse(tokens);

  return {
    accessToken,
    refreshToken,
  };
};

export const getBearerToken = () => {
  const tokens = localStorage.getItem("authToken");
  if (!tokens) {
    return null;
  }
  const { accessToken } = JSON.parse(tokens);

  const bearerToken = `Bearer ${accessToken}`;

  return bearerToken;
};

export const getLocalUser = () => {
  const user = localStorage.getItem("user");

  return user;
};
