import { MenuOutlined } from "@ant-design/icons";
import { Drawer, message } from "antd";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { styled } from "styled-components";
import AdminFunctionsApi from "../../api/admin/admin-functions";
import AuthApi from "../../api/auth";
import logo from "../../assets/logo_icon.png";
import useLocalStorage from "./../../hooks/localStorage.hook";
import AppMenu from "./components/AppMenu";
import ForgotPassPopup from "./components/ForgotPassPopup";
import LoginPopup from "./components/LoginPopup";
import ResetPasswordPopup from "./components/ResetPassPopup";
import SignUpPopup from "./components/SignUpPopup";

const ContainerOutline = styled.span`
  @media screen and (min-width: 600px) {
    display: none;
  }
`;

const HeaderMenu = styled.span`
  @media screen and (max-width: 600px) {
    display: none;
  }
`;

const Navbar = ({ action, otp, token }) => {
  const [, setAuthTokens] = useLocalStorage("authToken", "");
  const [user, setUser] = useLocalStorage("user", "");
  const [openMenu, setOpenMenu] = useState(false);
  const [notification, setNotification] = useState("");
  const [disabledOtp, setDisabledOtp] = useState(true);
  const [errorMessageEmail, setErrorMessageEmail] = useState();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const referralCode = searchParams.get("referralCode");

  useEffect(() => {
    if (referralCode && user) {
      alert(
        "You need to log out and enter by invitation link again to use the referral code"
      );
    }
  }, [referralCode, user]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    getValues,
  } = useForm({
    criteriaMode: "all",
    defaultValues: {
      email: "",
      password: "",
      name: "",
      referralCode: referralCode || "",
      otp: "",
    },
  });

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("user");
    window.location.replace("/");
  };

  const { isLoading: signUpLoading, mutate: signUp } = useMutation(
    (data) => {
      return AuthApi.register(
        data.name,
        data.email,
        data.password,
        data.referralCode,
        data.otp
      );
    },
    {
      onSuccess: (response) => {
        const resAuthTokens = response.data.authTokens;
        const resUser = response.data.user;
        setAuthTokens(resAuthTokens);
        setUser(resUser);
        // refresh page
        window.location.reload();
        //return message.success("Register success");
      },
      onError: (error) => {
        console.log(error);
        if (error.response.status === 500) {
          message.error("Internal Server error");
        } else {
          if (error.response.data?.message === "User already exists") {
            setErrorMessageEmail(
              "This email is already in use. If you have lost your password, click on the “Forgot password” option to retrieve it."
            );
          }
          message.error(
            error.response.data?.message || "Internal Server error"
          );
        }
        // reset({
        //   email: "",
        //   password: "",
        //   referralCode: "",
        //   name: "",
        //   confirmPassword: "",
        //   otp: "",
        // });
      },
    }
  );

  const { mutate: getOtp } = useMutation(
    () => AuthApi.sendConfirmEmail(getValues("email")),
    {
      onSuccess: () => {
        message.success("OTP has been sent to your email");
        setDisabledOtp(false);
      },
      onError: (error) => {
        if (error.response.status === 500) {
          message.error("Internal Server error");
        } else {
          if (error.response.data?.message === "User already exists") {
            setErrorMessageEmail(
              "This email is already in use. If you have lost your password, click on the “Forgot password” option to retrieve it."
            );
          }
          message.error(
            error.response.data?.message || "Internal Server error"
          );
        }
      },
    }
  );

  const onRegisterSubmit = (data) => {
    if (!data.otp) {
      message.error("Please enter OTP");
    } else if (data.password === data.confirmPassword) {
      signUp({ ...data });
    }
  };

  const onLoginSubmit = async (data) => {
    try {
      const response = await AuthApi.login(data.email, data.password);
      if (response.status === 200) {
        const resAuthTokens = response.data.authTokens;
        const resUser = response.data.user;

        setAuthTokens(resAuthTokens);
        setUser(resUser);
        // refresh page
        window.location.reload();
        return message.success("Login success");
      }
    } catch (error) {
      if (error.response.status === 500) {
        message.error("Internal Server error");
      } else {
        message.error(error.response.data?.message || "Internal Server error");
      }

      // reset({
      //   email: "",
      //   password: "",
      // });
    }
  };

  const onForgotPasswordSubmit = async (data) => {
    try {
      const response = await AuthApi.forgotPassword(data.email);
      if (response.status === 200) {
        // refresh page
        message.success("Please check your email to reset your password");
      }
    } catch (error) {
      console.log({ error });
      if (error.response.status === 500) {
        message.error("Internal Server error");
      } else {
        message.error(error.response.data?.message || "Internal Server error");
      }
    }
  };

  const [isModalOpenLogin, setIsModalOpenLogin] = useState(false);
  const [isModalOpenRegister, setIsModalOpenRegister] = useState(false);
  const [isModalOpenForgotPass, setIsModalOpenForgotPass] = useState(false);

  const showModalLogin = () => {
    setIsModalOpenLogin(true);
    reset({
      email: "",
      password: "",
    });
  };
  const handleCancelLogin = () => {
    setIsModalOpenLogin(false);
  };

  const handleCancelForgotPass = () => {
    setIsModalOpenForgotPass(false);
  };

  const showModalRegister = () => {
    setIsModalOpenRegister(true);
    reset({
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      otp: "",
    });
  };
  const handleCancelRegister = () => {
    setIsModalOpenRegister(false);
    setErrorMessageEmail("");
  };

  const { isLoading: isLoadingFunctionsDataSetting } = useQuery(
    "functionsDataSetting",
    async () => {
      return await AdminFunctionsApi.getFunctionsSetting();
    },
    {
      onSuccess: (data) => {
        setNotification(data.data.notificationMessage);
      },
      onError: (error) => {
        console.log(error);
        message.error("Error when fetching functions data setting");
      },
    }
  );

  return (
    <div>
      {notification && (
        <div
          style={{
            backgroundColor: "#eff1f2",
            alignItems: "center",
            borderBottom: "3px solid #a0138e",
            boxSizing: "border-box",
            display: "flex",
            height: "40px",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <p className="custom-font-notification">{notification}</p>
        </div>
      )}

      <ContainerOutline>
        <div
          style={{
            backgroundColor: "#2c2c2c",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <a href="/" target="_self" rel="icon">
            <img src={logo} alt="logo" />
          </a>
          <MenuOutlined
            style={{
              color: "white",
              fontSize: 30,
              paddingTop: 12,
              padding: "0 30px 0 15px",
            }}
            onClick={() => setOpenMenu(true)}
          />
        </div>
      </ContainerOutline>

      <HeaderMenu>
        <AppMenu
          showModalLogin={showModalLogin}
          showModalRegister={showModalRegister}
          handleLogout={handleLogout}
        />
      </HeaderMenu>

      <Drawer
        placement="left"
        open={openMenu}
        onClose={() => setOpenMenu(false)}
        closable={false}
        bodyStyle={{ backgroundColor: "#2c2c2c" }}
        width={250}
      >
        <AppMenu
          isInline
          showModalLogin={showModalLogin}
          showModalRegister={showModalRegister}
          handleLogout={handleLogout}
        />
      </Drawer>

      <LoginPopup
        isModalOpenLogin={isModalOpenLogin}
        handleCancelLogin={handleCancelLogin}
        handleSubmit={handleSubmit}
        onLoginSubmit={onLoginSubmit}
        control={control}
        errors={errors}
        setIsModalOpenLogin={setIsModalOpenLogin}
        setIsModalOpenRegister={setIsModalOpenRegister}
        isModalOpenForgotPass={isModalOpenForgotPass}
        setIsModalOpenForgotPass={setIsModalOpenForgotPass}
      />

      <SignUpPopup
        isModalOpenRegister={isModalOpenRegister}
        handleCancelRegister={handleCancelRegister}
        handleSubmit={handleSubmit}
        onRegisterSubmit={onRegisterSubmit}
        control={control}
        errors={errors}
        setIsModalOpenLogin={setIsModalOpenLogin}
        setIsModalOpenRegister={setIsModalOpenRegister}
        signUpLoading={signUpLoading}
        errorMessageEmail={errorMessageEmail}
        watch={watch}
        isModalOpenForgotPass={isModalOpenForgotPass}
        setIsModalOpenForgotPass={setIsModalOpenForgotPass}
        disabledOtp={disabledOtp}
        getOtp={getOtp}
      />
      <ForgotPassPopup
        isModalOpenRegister={isModalOpenRegister}
        handleCancelRegister={handleCancelRegister}
        handleSubmit={handleSubmit}
        onRegisterSubmit={onRegisterSubmit}
        control={control}
        errors={errors}
        setIsModalOpenLogin={setIsModalOpenLogin}
        setIsModalOpenRegister={setIsModalOpenRegister}
        signUpLoading={signUpLoading}
        isModalOpenForgotPass={isModalOpenForgotPass}
        setIsModalOpenForgotPass={setIsModalOpenForgotPass}
        onForgotPasswordSubmit={onForgotPasswordSubmit}
        handleCancelForgotPass={handleCancelForgotPass}
      />
      <ResetPasswordPopup otp={otp} token={token} action={action} />
    </div>
  );
};

export default Navbar;
