import React, { useState } from "react";
import { Button, Pagination, Table, Space } from "antd";
import "../../General/general.css";
import dayjs from "dayjs";

const pendingWithdrawalData = [
  {
    id: 1,
    userId: "16990406F",
    name: "John Doe",
    amount: 100,
    dateRequested: "2024-02-02",
  },
  {
    id: 2,
    userId: "16990406F",
    name: "John Doe",
    amount: 100,
    dateRequested: "2024-02-02",
  },
];

const TablePendingWithdrawal = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const limitItems = 12;
  const totalItems = pendingWithdrawalData.length;

  //columns
  const pendingWithdrawalCols = [
    {
      title: <p className="font-bold">User ID</p>,
      dataIndex: "userId",
      key: "userId",
      render: (text) => <p className="text-xs">{text}</p>,
    },
    {
      title: <p className="font-bold">Name</p>,
      dataIndex: "name",
      key: "name",
      render: (text) => <p className="text-xs">{text}</p>,
    },
    {
      title: <p className="font-bold">Amount</p>,
      dataIndex: "amount",
      key: "amount",
      render: (text) => <p className="text-xs">{text}</p>,
    },
    {
      title: <p className="font-bold">Date Requested</p>,
      dataIndex: "dateRequested",
      key: "dateRequested",
      render: (text) => (
        <p className="text-xs">{dayjs(text).format("DD MMM YYYY")}</p>
      ),
    },
    {
      title: <p className="text-transparent">Action</p>,
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <p className="custom-btn-sign-up-code cursor-pointer">Reject</p>
          <p className="custom-btn-sign-up-code cursor-pointer">Approve</p>
        </Space>
      ),
    },
  ];

  // Table
  const startIndex = (currentPage - 1) * limitItems + 1;
  const endIndex = Math.min(currentPage * limitItems, totalItems);

  const currentPageData = pendingWithdrawalData.slice(startIndex - 1, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  return (
    <div>
      <Table
        style={{
          borderCollapse: "separate",
          tableLayout: "fixed",
          width: "100%",
          marginTop: 10,
        }}
        columns={pendingWithdrawalCols}
        dataSource={currentPageData}
        pagination={false}
        size="small"
        bordered={false}
        className="wallet-table "
      />
      <div className="mt-3 text-xs font-bold">
        Showing {startIndex} - {endIndex} of {totalItems} withdrawals requests
      </div>
      <div className="flex justify-center mt-3">
        <Button type="text" onClick={() => setCurrentPage(1)}>
          First
        </Button>
        <Pagination
          current={currentPage}
          pageSize={limitItems}
          total={pendingWithdrawalData.length}
          onChange={handlePageChange}
        />
        <Button
          type="text"
          onClick={() =>
            setCurrentPage(Math.ceil(pendingWithdrawalData.length / limitItems))
          }
        >
          Last
        </Button>
      </div>
    </div>
  );
};

export default TablePendingWithdrawal;
