import { Button, Form, Input, Modal, Typography, message } from "antd";
import React, { useState } from "react";
import { useMutation } from "react-query";
import AuthApi from "../../../../api/auth";
import UserInfoApi from "../../../../api/profile/user-info";

const ChangeWithdrawalPin = ({
  setOpenWithdrawalPin,
  openWithdrawalPin,
  user,
}) => {
  const [form] = Form.useForm();
  const [messageApi] = message.useMessage();
  const [formNewWithdrawalPin] = Form.useForm();
  const [openNewWithdrawalPin, setOpenNewWithdrawalPin] = useState(false);
  //const [errorMessages, setErrorMessages] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);

  const { mutate: verifyPassword } = useMutation(
    async (values) => {
      const res = await AuthApi.verifyPassword(values.currentPassword);
      return res;
    },
    {
      onSuccess: (res) => {
        setOpenNewWithdrawalPin(true);
        form.resetFields("currentPassword");
        //setErrorMessages(false);
      },
      onError: (error) => {
        message.error(error.response.data.message)
        //setErrorMessages(true);
      },
    }
  );

  const checkPassword = async (values) => {
    verifyPassword(values);
  };

  const handleCancelChangeWithdrawalPin = () => {
    setOpenWithdrawalPin(false);
    //setErrorMessages(false);
    form.resetFields();
  };

  const { mutate: putUserInfo } = useMutation(
    async (userData) => {
      return await UserInfoApi.putUserInfo(user?.id, userData);
    },
    {
      onSuccess: (res) => {
        setOpenNotification(true);
      },
      onError: (error) => {
        messageApi.open({
          type: "error",
          content: "Error while update info",
        });
      },
    }
  );

  const handleSaveWithdrawalPin = (values) => {
    if (values.newWithdrawalPin !== values.confirmWithdrawalPin) {
      formNewWithdrawalPin.setFields([
        {
          name: "confirmWithdrawalPin",
          errors: ["Entered withdrawal pin do not match"],
        },
      ]);
    } else {
      putUserInfo({ withdrawalPin: values.newWithdrawalPin });
    }
  };

  const handleCancelNewWithdrawalPin = () => {
    setOpenNewWithdrawalPin(false);
  };

  const handleCancelOpenNotification = () => {
    setOpenNotification(false);
    formNewWithdrawalPin.resetFields();
    setOpenNewWithdrawalPin(false);
    setOpenWithdrawalPin(false);
  };

  return (
    <>
      <Modal
        open={openWithdrawalPin}
        onCancel={handleCancelChangeWithdrawalPin}
        footer={null}
        closeIcon={false}
        className="info-modal"
      >
        <Typography className="text-xl font-bold text-center">
          Change Withdrawal Pin
        </Typography>
        <Form
          form={form}
          layout="vertical"
          className="mt-4"
          onFinish={checkPassword}
        >
          <Typography className="font-bold text-sm py-2">
            Current Password
          </Typography>
          <div className="flex flex-col justify-center">
            <Form.Item
              name="currentPassword"
              rules={[
                {
                  required: true,
                  message: "Please input your current password!",
                },
                // {
                //   pattern: passwordRegExp,
                //   message: "Please input a valid password!",
                // },
              ]}
            >
              <Input
                placeholder="Enter your current password"
                className="info-input"
                type="password"
              />
              {/* {errorMessages && (
                <p className="text-red-500 text-xs italic mt-1">
                  Password is incorrect
                </p>
              )} */}
            </Form.Item>
            <Form.Item className="mx-auto">
              <Button htmlType="submit">Next</Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>

      <Modal
        open={openNewWithdrawalPin}
        onCancel={handleCancelNewWithdrawalPin}
        footer={null}
        closeIcon={false}
        className="info-modal"
      >
        <Typography className="text-xl font-bold text-center">
          Change Withdrawal Pin
        </Typography>
        <Form
          form={formNewWithdrawalPin}
          layout="vertical"
          className="mt-4"
          onFinish={handleSaveWithdrawalPin}
        >
          <div className="flex flex-col justify-center">
            <Typography className="font-bold text-sm py-2">
              New Withdrawal Pin
            </Typography>
            <Form.Item
              name="newWithdrawalPin"
              rules={[
                {
                  required: true,
                  message: "Please input your new withdrawal pin!",
                },
              ]}
            >
              <Input
                placeholder="Enter your new withdrawal pin"
                className="info-input"
                type="text"
              />
            </Form.Item>
            <Typography className="font-bold text-sm py-2">
              Confirm WithdrawalPin
            </Typography>
            <Form.Item
              name="confirmWithdrawalPin"
              rules={[
                {
                  required: true,
                  message: "Please input your confirm withdrawal pin!",
                },
              ]}
            >
              <Input
                placeholder="Enter your confirm withdrawal pin"
                className="info-input"
                type="text"
              />
            </Form.Item>
            <Form.Item className="mx-auto">
              <Button htmlType="submit">Save</Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>

      <Modal
        open={openNotification}
        onCancel={handleCancelOpenNotification}
        footer={null}
        className="info-modal mt-10 "
        width={450}
      >
        <Typography>
          Your Withdrawal Pin has been updated successfully!
        </Typography>
      </Modal>
    </>
  );
};

export default ChangeWithdrawalPin;
