import {combineReducers} from "@reduxjs/toolkit";
import UI_reducer from "../components/reducer"
// import { api } from "@/api/index";
import { authSlice } from './auth.redurcer';
// import auth, {reducerForJWT} from './auth.reducer'
// import {logout as logoutAction} from './auth.reducer'

const appReducer = combineReducers({
    ui: UI_reducer,
    // auth,
    // jwt : reducerForJWT,
    user: authSlice.reducer,
    // [api.reducerPath] : api.reducer
});

const rootReducer = (state, action) => {
    // if (logoutAction.match(action)) {
    //     state = {};
    // }

    return appReducer(state, action)
}

export default rootReducer;