import axios from "axios";
axios.defaults.baseURL =
  process.env.REACT_APP_PUBLIC_API_URL;

export default class AdminStakingApi {
  static async updateStakingSetting(stakingSetting) {
    const res = await axios.post("/admin/setting/staking", stakingSetting);
    return res;
  }

  static async getStakingSetting() {
    const res = await axios.get("/admin/setting/staking");
    return res;
  }
}
