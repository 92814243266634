import { Typography, Input } from "antd";
import dayjs from "dayjs";
import React from "react";

const LayoutLine2 = ({ userDetail }) => {
  return (
    <div className="flex flex-col mt-10 gap-3">
      <div
        className="border-none p-3 rounded-md"
        style={{
          backgroundColor: "#ededed",
          color: "#252525",
          width: "465px",
        }}
      >
        <Typography className="font-bold" style={{ fontSize: "13px" }}>
          User Detail
        </Typography>
        <div className="flex justify-between pt-2" style={{ fontSize: "12px" }}>
          <div className="flex flex-col gap-2">
            <p>Name: {userDetail.name}</p>
            <p>Email: {userDetail.email}</p>
            {/* <p>Mobile: {userDetail.mobile}</p>
            <p>
              Joined Date: {dayjs(userDetail.createdAt).format("DD MMM YYYY")}
            </p> */}
          </div>
          <div className="flex flex-col gap-2">
            <p>Sign Up Code: 00000</p>
            <p>
              Joined Date: {dayjs(userDetail.createdAt).format("DD MMM YYYY")}
            </p>
            {/* <p>Cluster Head: {userDetail.clusterHead}</p>
            <p>Rank: {userDetail.rank}</p>
            <p>
              KYC Status: <span style={{ color: "#3652DC" }}>Pending</span>
            </p>
            <p>
              Account Status: <span style={{ color: "#3652DC" }}>Active</span>
            </p> */}
          </div>
        </div>
      </div>
      <div
        className="border-none p-3 pb-10 rounded-md"
        style={{
          backgroundColor: "#ededed",
          color: "#252525",
          width: "465px",
        }}
      >
        <Typography className="font-bold" style={{ fontSize: "14px" }}>
          Deposit/ Withdrawal & Balance
        </Typography>
        <div className="flex justify-between pt-2" style={{ fontSize: "12px" }}>
          <div className="flex flex-col gap-2">
            <p>
              Available balance: {userDetail.availableBalance} USDT{" "}
              {/* <span style={{ color: "#3652DC" }}>Adjust</span> */}
            </p>
            <p>Staked Balance: {userDetail.totalBalance} USDT</p>
            <p>Total Balance: {userDetail.totalBalance} USDT</p>
            {/* <p>Locked Up Balance: {userDetail.stakedBalance} USDT</p>
            <p>
              Holding Balance: {userDetail.holdingBalance} USDT{" "}
              <span style={{ color: "#3652DC" }}>Release</span>
            </p>
            <p>Total Deposit: {userDetail.totalDeposit} USDT</p> */}
          </div>
          <div className="flex flex-col gap-2">
            <p>Total Deposit: {userDetail.totalDeposit} USDT</p>
            <p>Total Withdrawal: {userDetail.totalWithdrawal} USDT</p>
            <p>Difference: {userDetail.totalDifference} USDT</p>
          </div>
        </div>

        <div className="flex flex-col gap-3">
          <Typography className="font-bold mt-7" style={{ fontSize: "14px" }}>
            Balance Adjustment
          </Typography>
          <Input placeholder="Description" size="small" />
          <div className="flex justify-between">
            <div className="flex gap-2">
              <Input placeholder="Enter Amount" size="small" />{" "}
              <span className="my-auto">USDT</span>
            </div>
            <button className="custom-btn-layout-line2">Confirm</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LayoutLine2;
