import { Typography } from "antd";
import React from "react";

const LayoutLine1 = ({ id, userId, handleChangeUserID, handleSearch }) => {
  return (
    <div className="flex mt-10 justify-between">
      <div>
        <Typography className="font-bold text-xl">User ID: {userId}</Typography>
      </div>
      <div className="flex gap-2">
        <input
          value={userId}
          placeholder="User ID"
          onChange={handleChangeUserID}
          className="border rounded border-black p-1"
          style={{ width: "150px", height: "25px", fontSize: "12px" }}
        />
        <button
          className="border-none ml-2"
          style={{
            backgroundColor: "#afaeae",
            fontSize: "12px",
            width: "85px",
            height: "25px",
            letterSpacing: "3px",
          }}
          onClick={handleSearch}
        >
          Search
        </button>
      </div>
    </div>
  );
};

export default LayoutLine1;
