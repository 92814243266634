import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AuthApi from '../api/auth';

/**
 * interface ThunkAPI {
  dispatch: Function
  getState: Function
  extra?: any
  requestId: string
  signal: AbortSignal
}
 */

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: null,
    user: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload.user;
    },
    setAuth: (state, action) => {
      state.token = action.payload.token;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.user = action.payload;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.user = null;
      console.error(action.error.message);
    });
    builder.addCase(register.fulfilled, (state, action) => {
      state.user = action.payload;
    });
    builder.addCase(register.rejected, (state, action) => {
      state.user = null;
      console.error(action.error.message);
    });
    builder.addCase(refreshToken.fulfilled, (state, action) => {
      state.user = action.payload;
    });
    builder.addCase(refreshToken.rejected, (state, action) => {
      state.user = null;
      console.error(action.error.message);
    });
  },
});

export const login = createAsyncThunk(
  'auth/loginStatus',
  async (credential, thunkAPI) => await AuthApi.login(credential),
);

export const register = createAsyncThunk(
  'auth/registerStatus',
  async (credential, thunkAPI) => await AuthApi.register(credential),
)

export const refreshToken = createAsyncThunk(
  'auth/refreshTokenStatus',
  async (args, thunkAPI) => await AuthApi.refreshToken(),
);

export const { setUser } = authSlice.actions;
