import { Button, Input, InputNumber, Modal, Typography, message } from "antd";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import UserApi from "../../api/user";
import WalletApi from "../../api/wallet";
import Loading from "../Loading";

const Withdraw = ({ isModalOpen, setIsModalOpen, refetch }) => {
  const [accountInfo, setAccountInfo] = useState({});
  const [withdrawAmount, setWithdrawAmount] = useState(0);
  const [walletId, setWalletId] = useState("");
  const { isLoading: accountInfoLoading } = useQuery(
    "getSelf",
    UserApi.getSelf,
    {
      onSuccess: (res) => {
        setAccountInfo(res.data);
        setWalletId(res.data.appWallet);
      },
      onError: (error) => {
        message.error("Error while Getting available balance");
      },
    }
  );

  const { mutate: withdrawWallet, isLoading: withdrawWalletLoading } =
    useMutation(
      async (walletId) => {
        return await WalletApi.withdraw(walletId, withdrawAmount);
      },
      {
        onSuccess: (res) => {
          message.success("Withdraw success");
          refetch();
        },
        onError: (error) => {
          message.error("Error while Withdraw");
        },
      }
    );

  const handleWithdraw = () => {
    withdrawWallet(withdrawAmount);
  };

  return (
    <Modal
      open={isModalOpen}
      onOk={() => setIsModalOpen(false)}
      onCancel={() => setIsModalOpen(false)}
      footer={null}
      closeIcon={false}
      className="text-white"
    >
      {accountInfoLoading ? (
        <Loading />
      ) : (
        <div className="flex flex-col gap-3">
          <div className="flex gap-2 justify-start items-center">
            <Typography className="font-semibold text-white">Withdraw amount:</Typography>
            <InputNumber
              onChange={(value) => setWithdrawAmount(value)}
              min={0}
              max={accountInfo?.availableBalance || 0}
            />
          </div>
          <Typography className="font-semibold text-white">Network: </Typography>
          <Input />
          <Typography className="font-semibold text-white">Wallet ID:</Typography>
          <Input
            onChange={(e) => setWalletId(e.target.value)}
            value={walletId}
          />
          <Button
            onClick={handleWithdraw}
            loading={withdrawWalletLoading}
            className="w-2/5 m-auto text-white"
          >
            Submit
          </Button>
        </div>
      )}
    </Modal>
  );
};

export default Withdraw;
