import { Form, Input, Modal, Typography, message, Button } from "antd";
import React from "react";
import AuthApi from "../../../api/auth";
import { passwordRegExp } from "../../../utils/regFormat";

const ResetPasswordPopup = ({action, otp, token }) => {
  const [form] = Form.useForm();
  const handleSavePassword = async (values) => {
    try {
      const res = await AuthApi.resetPassword(
        token,
        otp,
        values.newPassword
      );
      if (res.status === 200) {
        message.success("Password reset successfully");
        setTimeout(() => {
          window.location.replace("/");
        }, 2000);
      }
    } catch (error) {
      if (error.response.status === 500) {
        message.error("Internal Server error");
      } else {
        message.error("Error while getting user detail");
      }
    }
  }
  return (
    <Modal
      open={action === "RESET_PASSWORD"}
      onCancel={() => {
        window.location.replace("/");
      }}
      closeIcon={false}
      footer={null}
      style={{ width: "460px" }}
    >
      {/* <div>

        <form
          onSubmit={async (e) => {
            try {
              const res = await AuthApi.resetPassword(e.target.password.value, token, otp);
              if (res.status === 200) {
                message.success("Password reset successfully");
                setTimeout(() => {
                  window.location.replace("/");
                }, 2000);
              }
            } catch (error) {
              if (error.response.status === 500) {
                message.error("Internal Server error");
              } else {
                message.error("Error while getting user detail");
              }
            }


            window.location.replace("/");
          }}
          className="flex flex-col p-5"
        >
          <Typography className="text-xl font-bold text-center">
          Enter your new password
        </Typography>
          <InputPass
            control={control}
            name="password"
            placeholder="New Password"
            errors={errors}
          />
          <InputPass
            control={control}
            name="rePassword"
            placeholder="Re-enter New Password"
            errors={errors}
          />
          <ErrorMessage
            errors={errors}
            name="password"
            render={({ message }) => (
              <p className="text-red-500 text-xs italic mb-2">{message}</p>
            )}
          />

          <button
            type="submit"
            style={{
              height: "36px",
              backgroundColor: "#2c2c2c",
              transition: "all 0.2s ease, visibility 0s",
              fontSize: "15px",
            }}
            className="col-start-5 font-semibold text-white rounded-lg m-auto w-full"
          >
            SUBMIT
          </button>
        </form>
      </div> */}
      <Typography className="text-xl font-bold text-center">
          Enter New Password
        </Typography>
      <Form
        form={form}
        layout="vertical"
        className="mt-4"
        onFinish={handleSavePassword}
      >
        <div className="flex flex-col justify-center">
          <Typography className="font-bold text-sm py-2">
            New Password
          </Typography>
          <Form.Item
            name="newPassword"
            rules={[
              {
                required: true,
                message: "Please input your new password!",
              },
              {
                pattern: passwordRegExp,
                message: "Please input a valid password!",
              },
            ]}
          >
            <Input
              placeholder="Enter your confirm password"
              className="info-input"
              type="password"
            />
          </Form.Item>
          <Typography className="font-bold text-sm py-2">
            Confirm Password
          </Typography>
          <Form.Item
            name="confirmPassword"
            rules={[
              {
                required: true,
                message: "Please input your confirm password!",
              },
              {
                pattern: passwordRegExp,
                message: "Please input a valid password!",
              },
            ]}
          >
            <Input
              placeholder="Enter your confirm password"
              className="info-input"
              type="password"
            />
          </Form.Item>
          <Form.Item className="mx-auto">
            <Button htmlType="submit">Save</Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default ResetPasswordPopup;
