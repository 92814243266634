import React, { useState } from "react";
import { Button, Pagination, Table } from "antd";
import "../general.css";

const signUpCodeData = [
  {
    id: 1,
    description: "$10 USDT Sign Up Promotion (2 - 29 Feb 2024)",
    code: "A5072",
  },
  {
    id: 2,
    description: "March Referral Program",
    code: "B5072",
  },
];

const TableCode = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const limitItems = 12;
  const totalItems = signUpCodeData.length;

  //columns
  const signUpCodeCols = [
    {
      title: <p className="font-bold">Description</p>,
      dataIndex: "description",
      key: "description",
      render: (text) => <p className="text-xs">{text}</p>,
    },
    {
      title: <p className="font-bold">Code</p>,
      dataIndex: "code",
      key: "code",
      render: (text) => <p className="text-xs">{text}</p>,
    },
    {
      title: <p className="text-transparent">Action</p>,
      key: "action",
      render: (_, record) => (
        <p className="custom-btn-sign-up-code cursor-pointer">Delete</p>
      ),
    },
  ];

  // Table
  const startIndex = (currentPage - 1) * limitItems + 1;
  const endIndex = Math.min(currentPage * limitItems, totalItems);

  const currentPageData = signUpCodeData.slice(startIndex - 1, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  return (
    <div>
      <Table
        style={{
          borderCollapse: "separate",
          tableLayout: "fixed",
          width: "100%",
          marginTop: 10,
        }}
        columns={signUpCodeCols}
        dataSource={currentPageData}
        pagination={false}
        size="small"
        bordered={false}
        className="wallet-table "
      />

      <div className="flex justify-center mt-3">
        <Button type="text" onClick={() => setCurrentPage(1)}>
          First
        </Button>
        <Pagination
          current={currentPage}
          pageSize={limitItems}
          total={signUpCodeData.length}
          onChange={handlePageChange}
        />
        <Button
          type="text"
          onClick={() =>
            setCurrentPage(Math.ceil(signUpCodeData.length / limitItems))
          }
        >
          Last
        </Button>
      </div>
      <div className="mt-3 text-xs">
        Showing {startIndex} - {endIndex} of {totalItems} sign up codes
      </div>
    </div>
  );
};

export default TableCode;
