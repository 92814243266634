import { Col, Input, Row, Space } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import Image from "../../assets/homepage_image_2.jpg";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/index";
import { SelectMenu } from "../../components/Select";

const ContainerChildNav = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
`;

export const sortOptions = [
  {
    value: "1 Day",
    label: "1 Day",
  },
  {
    value: "7 Days",
    label: "7 Days",
  },
  {
    value: "14 Days",
    label: "14 Days",
  },
  {
    value: "30 Days",
    label: "30 Days",
  },
  {
    value: "60 Days",
    label: "60 Days",
  },
  {
    value: "90 Days",
    label: "90 Days",
  },
  {
    value: "180 Days",
    label: "180 Days",
  },
  {
    value: "360 Days",
    label: "360 Days",
  },
];

const HomePage = ({ title }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const action = searchParams.get("action");
  const token = searchParams.get("token");
  const otp = searchParams.get("otp");

  const [currentSortOption, setCurrentSortOption] = useState(
    sortOptions[0].value
  );

  const [stakedAmount, setStakedAmount] = useState(1000);

  const handleChange = (value) => {
    setCurrentSortOption(value);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Navbar
          action={action}
          otp={otp}
          token={token}        
        />
        <div
          style={{
            backgroundColor: "rgb(0,0,0)",
            fontFamily:
              "helvetica-w01-roman,helvetica-w02-roman,helvetica-lt-w10-roman,sans-serif",
          }}
        >
          <Row>
            <Col xs={0} sm={0} md={1} lg={2} xl={4} />
            <Col xs={24} sm={24} md={22} lg={20} xl={16}>
              <div className="flex flex-col justify-center gap-10 mx-3 my-14">
                <a href="/stake" target="_self" rel="icon">
                  <img
                    src={Image}
                    alt="homepage_image_1"
                    style={{ width: "100%" }}
                  />
                </a>
                <hr style={{ border: "1px solid white" }} />
                <div className="grid grid-flow-row justify-items-center gap-10 ">
                  <h3
                    className="text-white font-bold"
                    style={{
                      fontSize: "20px",
                    }}
                  >
                    Return Calculator
                  </h3>
                  <div
                    className="flex flex-wrap gap-6 px-7 py-10 justify-around rounded-lg"
                    style={{ backgroundColor: "white", width: "100%" }}
                  >
                    <div>
                      <SelectMenu
                        defaultValue={sortOptions[0].value}
                        handleChange={handleChange}
                        options={sortOptions}
                        width="w-32"
                        style={{}}
                      />
                      <p
                        className="pt-2"
                        style={{ fontSize: "12px", textAlign: "center" }}
                      >
                        LOCKED-IN PERIOD
                      </p>
                    </div>

                    <div>
                      <Space.Compact>
                        <Input
                          defaultValue={stakedAmount}
                          style={{ border: "1px solid black" }}
                          className="w-32"
                          onChange={(e) => {
                            setStakedAmount(e.target.value);
                          }}
                        />
                      </Space.Compact>
                      <p
                        className="pt-2"
                        style={{ fontSize: "12px", textAlign: "center" }}
                      >
                        STAKED AMOUNT
                      </p>
                    </div>

                    <div className="text-center">
                      <p style={{ fontSize: "20px" }}>3.5%</p>
                      <p className="pt-2" style={{ fontSize: "12px" }}>
                        DAILY APR
                      </p>
                    </div>

                    <div className="text-center">
                      <p style={{ fontSize: "20px" }}>4.54 USDT</p>
                      <p className="pt-2" style={{ fontSize: "12px" }}>
                        DAILY RETURN
                      </p>
                    </div>

                    <div className="text-center">
                      <p style={{ fontSize: "20px" }}>4.54 USDT</p>
                      <p className="pt-2" style={{ fontSize: "12px" }}>
                        MONTHLY RETURN
                      </p>
                    </div>

                    <div className="text-center">
                      <p style={{ fontSize: "20px" }}>1,634 USDT</p>
                      <p className="pt-2" style={{ fontSize: "12px" }}>
                        ANNUAL RETURN
                      </p>
                    </div>
                  </div>
                  <button
                    style={{
                      letterSpacing: "0.1em",
                      color: "#252525",
                      fontSize: "15px",
                      fontFamily: "sans-serif",
                      padding: "10px 20px",
                      backgroundColor: "white",
                    }}
                    onClick={() => navigate("/stake")}
                  >
                    STAKE NOW
                  </button>
                </div>
                <hr style={{ border: "1px solid white" }} />
                <div className="grid grid-flow-row justify-items-center gap-10 h-full">
                  <h3
                    className="text-white font-bold"
                    style={{
                      fontSize: "20px",
                    }}
                  >
                    About Us
                  </h3>
                  <div className="grid 2xl:grid-cols-5 sm:grid-rows-1 gap-7 mx-10">
                    <div className="2xl:col-span-2 my-auto">
                      <div
                        style={{ backgroundColor: "white", padding: "20px" }}
                      >
                        <h4
                          style={{
                            fontSize: "18px",
                            color: "rgb(37,37,37)",
                            fontWeight: "bold",
                          }}
                        >
                          WHO ARE WE?
                        </h4>
                        <p style={{ fontSize: "16px", paddingTop: "10px" }}>
                          <span className="font-medium">SAUDI DE-FI </span>
                          is a Saudi Arabia based decentralised finance
                          corporation. Our team has years of experience in the
                          digital currency market, specialises in the finance
                          and funding industry.
                        </p>
                      </div>
                    </div>
                    <div className="2xl:col-span-3 flex flex-col 2xl:gap-14 gap-7">
                      <div
                        style={{ backgroundColor: "white", padding: "20px" }}
                        className="col-span-2"
                      >
                        <h4
                          style={{
                            fontSize: "18px",
                            color: "rgb(37,37,37)",
                            fontWeight: "bold",
                          }}
                        >
                          WHAT DO WE DO?
                        </h4>
                        <p
                          style={{ fontSize: "16px", paddingTop: "10px" }}
                          className="font-semibold"
                        >
                          We analyse the movement of crypto market and help
                          businesses and individual investors to achieve their
                          goal by providing funds to them as a form of loan
                          through our partners platform.
                        </p>
                      </div>
                      <div
                        style={{ backgroundColor: "white", padding: "20px" }}
                        className="col-span-2"
                      >
                        <h4
                          style={{
                            fontSize: "18px",
                            color: "rgb(37,37,37)",
                            fontWeight: "bold",
                          }}
                        >
                          OUR CLIENTS & PARTNERS
                        </h4>
                        <p
                          style={{ fontSize: "16px", paddingTop: "10px" }}
                          className="font-semibold"
                        >
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={0} sm={0} md={1} lg={2} xl={4} />
          </Row>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default HomePage;
