import axios from "axios";
import { getBearerToken } from "../../utils/localStorage";
axios.defaults.baseURL =
  process.env.REACT_APP_PUBLIC_API_URL;

export default class UserInfoApi {
  static async putUserInfo(userId, userData) {
    const token = getBearerToken();
    const res = await axios.put(`/user/${userId}`, userData, {
      headers: {
        authorization: token,
      },
    });
    return res;
  }

  static async getInfoDetail() {
    const token = getBearerToken();
    const res = await axios.get("/staking/me/detail", {
      headers: {
        authorization: token,
      },
    });
    return res;
  }
}
