import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import AdminStakingApi from "../../../../api/admin/admin-staking";
import Widget from "../../../../components/Widget";
import StakeSettingLayout from "./components/StakeSettingLayout";
import TimeZoneLayout from "./components/TimeZoneLayout";
import { LoadingOutlined } from "@ant-design/icons";
import { useQuery, useMutation } from "react-query";
import { message } from "antd";

const Staking = () => {
  const [data, setData] = useState({});
  const { control, handleSubmit, reset } = useForm({
    defaultValues: data,
  });

  const { isLoading: isLoadingStakingSetting } = useQuery(
    "stakingSetting",
    async () => {
      return await AdminStakingApi.getStakingSetting();
    },
    {
      onSuccess: (data) => {
        setData(data.data);
        reset(data.data);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const mutateUpdateStakingSetting = useMutation(
    (data) => {
      return AdminStakingApi.updateStakingSetting(data);
    },
    {
      onSuccess: (data) => {
        setData(data.data);
        reset(data.data);
        message.success("Update staking setting successfully");
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const onSubmit = (data) => {
    mutateUpdateStakingSetting.mutate(data);
    reset(data);
  };

  return (
    <Widget className="mt-4 border-none" style={{ color: "#252525" }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TimeZoneLayout control={control} data={data} />
        <StakeSettingLayout control={control} data={data} />
        <div className="grid grid-cols-9 my-16">
          <button
            type="submit"
            style={{
              width: "143px",
              height: "32px",
              backgroundColor: "#ededed",
              transition: "all 0.2s ease, visibility 0s",
              fontWeight: "lighter",
              fontSize: "15px",
            }}
            className="col-start-3"
          >
            {mutateUpdateStakingSetting.isLoading ? (
              <LoadingOutlined />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </form>
    </Widget>
  );
};

export default Staking;
