import { Divider, InputNumber, Modal } from "antd";
import dayjs from "dayjs";
import React, { useMemo, useState } from "react";
import AlertPopup from "./AlertPopup";
import "./stake.css";

const PopupStake = ({
  availableBalance,
  handlePostNewStake,
  open,
  duration,
  setDuration,
  handleCancel,
  stakeAmount,
  setStakeAmount,
  stakeUnlockDateOption,
  handleSetStakeUnlockDateOption,
  rankAndLimitsSettings,
  selfData,
  lockedUpDuration,
  isLogin,
}) => {
  const [isAlertPopupOpen, setIsAlertPopupOpen] = useState(false);

  const parsedDuration = useMemo(() => {
    if (duration === "Flexible") return 1;
    return parseInt(duration);
  }, [duration]);

  const handleCancelAlertPopup = () => {
    setIsAlertPopupOpen(false);
  };

  return (
    <Modal
      closeIcon={false}
      open={open}
      footer={null}
      onCancel={handleCancel}
      width={800}
      centered
    >
      <div className="flex gap-2">
        <div
          className="flex flex-col gap-3"
          style={{ width: "60%", color: "black" }}
        >
          <p style={{ fontSize: "16px" }} className="font-semibold">
            USDT STAKING OPTION
          </p>
          <p style={{ fontSize: "13px" }} className="font-semibold">
            Stake Period
          </p>
          <div className="">
            {lockedUpDuration?.map((data, index) => (
              <p
                key={index}
                className="inline-block border box-border rounded-md my-2 text-center custom-duration-btn"
                style={{
                  fontSize: "11px",
                  backgroundColor: duration === data ? "black" : "white",
                  color: duration === data ? "white" : "black",
                  cursor: duration === data ? "default" : "pointer",
                  borderColor: "grey",
                }}
                onClick={() => setDuration(data)}
              >
                {data}
              </p>
            ))}
          </div>

          <div className="flex justify-between">
            <p style={{ fontSize: "12px" }}>Stake Amount</p>
          </div>
          <InputNumber
            max={Math.min(
              rankAndLimitsSettings[`stakeLimitCapLv${selfData?.rank || 1}`],
              availableBalance
            )}
            min={10}
            className="w-full"
            value={stakeAmount}
            onChange={(value) => setStakeAmount(value)}
            placeholder="Enter Stake Amount"
            suffix={
              <div className="flex gap-1">
                <p
                  style={{
                    fontSize: "13px",
                    letterSpacing: "0.01em",
                    fontWeight: "bold",
                  }}
                >
                  USDT
                </p>
                <span>l </span>
                <p
                  style={{
                    fontSize: "13px",
                    letterSpacing: "0.01em",
                    fontWeight: "bold",
                    color: "#00973a",
                  }}
                >
                  {/* {Math.min(
                    rankAndLimitsSettings[
                      `stakeLimitCapLv${selfData?.rank || 1}`
                    ],
                    availableBalance
                  )}{" "} */}
                  MAX
                </p>
              </div>
            }
          />
          <div className="flex justify-end">
            <p style={{ fontSize: "12px" }}>
              Amount available: {availableBalance} USDT
            </p>
          </div>

          {/* <div
            className="flex gap-1"
            style={{
              fontSize: "14px",
              letterSpacing: "0.01em",
              fontWeight: "bold",
            }}
          >
            <p style={{ color: "#00973a" }}>Your Current Staking Limit: </p>
            <p>
              {rankAndLimitsSettings[`stakeLimitCapLv${selfData?.rank || 1}`]}{" "}
              USDT{" "}
              <span style={{ color: "#2b3afe", fontWeight: "normal" }}>?</span>
            </p>
          </div> */}
        </div>
        <Divider type="vertical" />
        <div
          style={{ width: "40%", fontSize: "12px", color: "rgb(37,37,37" }}
          className="flex flex-col gap-3"
        >
          <p style={{ fontSize: "13px" }}>USDT STAKING SUMMARY</p>
          <div className="mt-2">
            <div className="flex justify-between">
              <p>Income Payout Frequency</p>
              <p>Daily</p>
            </div>
            <Divider plain={true} className="m-2" />
            <div className="flex justify-between">
              <p>Daily Return Rate</p>
              <p>1.5%</p>
            </div>
            <Divider plain={true} className="m-2" />
            <div className="flex justify-between">
              <p>Daily Income</p>
              <p>15 USDT</p>
            </div>
            <Divider plain={true} className="m-2" />
            <div className="flex justify-between">
              <p>Period Income</p>
              <p>105 USDT</p>
            </div>
            <Divider plain={true} className="m-2" />
            <div className="flex justify-between">
              <p>Stake Date</p>
              <p>{dayjs().format("DD MMM YYYY")}</p>
            </div>
            <Divider plain={true} className="m-2" />
            <div className="flex justify-between">
              <p>Stake Unlock Date</p>
              <p>{dayjs().add(parsedDuration, "days").format("DD MMM YYYY")}</p>
            </div>
            {/* <Divider plain={true} className="m-2" />
            <p style={{ fontSize: "10px" }}>
              *You will be able to change your option during the staking period
            </p> */}
          </div>
          <div className="flex justify-end">
            <button
              className="border text-left text-white my-3 py-1 px-10 w-fit"
              style={{ backgroundColor: "#00973A", letterSpacing: "0.1em" }}
              onClick={handlePostNewStake}
            >
              STAKE
            </button>
          </div>
        </div>
      </div>
      <AlertPopup
        isAlertPopupOpen={isAlertPopupOpen}
        handleCancelAlertPopup={handleCancelAlertPopup}
      />
    </Modal>
  );
};

export default PopupStake;
