import React from "react";
import { Modal } from "antd";

const AlertPopup = ({ isAlertPopupOpen, handleCancelAlertPopup }) => {
  return (
    <Modal
      closeIcon={false}
      open={isAlertPopupOpen}
      footer={null}
      onCancel={handleCancelAlertPopup}
      centered
    >
      <div className="flex flex-col gap-3">
        <p style={{ fontSize: "16px" }} className="font-semibold">
          You need to login to access this feature. Please login first
        </p>
      </div>
    </Modal>
  );
};

export default AlertPopup;
