export const lockedUpDuration = [
  "Flexible",
  "7 Days",
  "14 Days",
  "30 Days",
  "60 Days",
  "90 Days",
  "180 Days",
  "360 Days",
];

export const stakingData = [
  {
    key: 1,
    lockedUpDuration: "Flexible",
    rateOfReturn: "1.36%",
    minInvestmentAmount: "10 USDT",
  },
  {
    key: 2,
    lockedUpDuration: "7 Days",
    rateOfReturn: "1.36%",
    minInvestmentAmount: "8 USDT",
  },
  {
    key: 3,
    lockedUpDuration: "14 Days",
    rateOfReturn: "1.36%",
    minInvestmentAmount: "10 USDT",
  },
  {
    key: 4,
    lockedUpDuration: "30 Days",
    rateOfReturn: "1.36%",
    minInvestmentAmount: "19 USDT",
  },
  {
    key: 5,
    lockedUpDuration: "60 Days",
    rateOfReturn: "1.36%",
    minInvestmentAmount: "10 USDT",
  },
  {
    key: 6,
    lockedUpDuration: "90 Days",
    rateOfReturn: "1.36%",
    minInvestmentAmount: "20 USDT",
  },
  {
    key: 7,
    lockedUpDuration: "180 Days",
    rateOfReturn: "1.36%",
    minInvestmentAmount: "10 USDT",
  },
  {
    key: 8,
    lockedUpDuration: "360 Days",
    rateOfReturn: "1.36%",
    minInvestmentAmount: "10 USDT",
  },
];

export const sortStakeOptions= [
  {
    value: "Restake",
    label: "Restake",
  },
  {
    value: "Redeem",
    label: "Redeem",
  },
]

export const ANR = 5;