import axios from "axios";
axios.defaults.baseURL =
  process.env.REACT_APP_PUBLIC_API_URL;

export default class AdminFunctionsApi {
  static async updateFunctionsSetting(functionsSetting) {
    const res = await axios.post("/admin/setting/frontend", functionsSetting);
    return res;
  }

  static async getFunctionsSetting() {
    const res = await axios.get("/admin/setting/frontend");
    return res;
  }
}
