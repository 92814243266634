import { Col, DatePicker, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import UserInfoCheckbox from "../../components/Checkbox/UserInfoCheckbox";
import AdminNavbar from "../../components/Navbar/AdminNavbar";
import {
  cumulativeOptions,
  nonCumulativeOptions,
  nonQuantitativeOptions,
  userInfo,
  userInfoCols,
} from "./components/userInfoData";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import "./components/userInfo.css";

dayjs.extend(customParseFormat);

const dateFormat = "MM/DD/YYYY";

const UserInfo = ({ title }) => {
  const [checkedNonQuantitativeList, setCheckedNonQuantitativeList] = useState(
    []
  );
  const [indeterminateNonQuantitative, setIndeterminateNonQuantitative] =
    useState(true);
  const [checkAllNonQuantitative, setCheckAllNonQuantitative] = useState(false);

  const [checkedCumulativeList, setCheckedCumulativeList] = useState([]);
  const [indeterminateCumulative, setIndeterminateCumulative] = useState(true);
  const [checkAllCumulative, setCheckAllCumulative] = useState(false);

  const [checkedNonCumulativeList, setCheckedNonCumulativeList] = useState([]);
  const [indeterminateNonCumulative, setIndeterminateNonCumulative] =
    useState(true);
  const [checkAllNonCumulative, setCheckAllNonCumulative] = useState(false);

  useEffect(() => {
    document.title = title;
  }, [title]);

  //Date and User ID
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [userId, setUserId] = useState("");

  //   Non-Quatiative
  const onChangeNonQuantitative = (list) => {
    setCheckedNonQuantitativeList(list);
    setIndeterminateNonQuantitative(
      !!list.length && list.length < nonQuantitativeOptions.length
    );
    setCheckAllNonQuantitative(list.length === nonQuantitativeOptions.length);
  };
  const onCheckAllChangeNonQuantitative = (e) => {
    setCheckedNonQuantitativeList(
      e.target.checked ? nonQuantitativeOptions : []
    );
    setIndeterminateNonQuantitative(false);
    setCheckAllNonQuantitative(e.target.checked);
  };

  //  Quantitative-Cumulative
  const onChangeCumulative = (list) => {
    setCheckedCumulativeList(list);
    setIndeterminateCumulative(
      !!list.length && list.length < cumulativeOptions.length
    );
    setCheckAllCumulative(list.length === cumulativeOptions.length);
  };
  const onCheckAllChangeCumulative = (e) => {
    setCheckedCumulativeList(e.target.checked ? cumulativeOptions : []);
    setIndeterminateCumulative(false);
    setCheckAllCumulative(e.target.checked);
  };

  //  Quatiative/ Non-Cumilative
  const onChangeNonCumulative = (list) => {
    setCheckedNonCumulativeList(list);
    setIndeterminateNonCumulative(
      !!list.length && list.length < nonCumulativeOptions.length
    );
    setCheckAllNonCumulative(list.length === nonCumulativeOptions.length);
  };
  const onCheckAllChangeNonCumulative = (e) => {
    setCheckedNonCumulativeList(e.target.checked ? nonCumulativeOptions : []);
    setIndeterminateNonCumulative(false);
    setCheckAllNonCumulative(e.target.checked);
  };

  const onChangeStartDate = (date, dateString) => {
    setEndDate(dateString);
  };

  const onChangeEndDate = (dateString) => {
    setStartDate(dateString);
  };

  const handleChangeUserID = (e) => {
    setUserId(e.target.value);
  };

  const handleSearch = () => {};

  return (
    <div className="h-screen xl:overflow-hidden sm:overflow-auto">
      <AdminNavbar />
      <Row gutter={16} justify="center">
        <Col xs={1} sm={1} md={1} lg={1} xl={2} />
        <Col xs={22} sm={22} md={22} lg={22} xl={20}>
          <div className="grid grid-cols-3 gap-1 mt-2">
            <UserInfoCheckbox
              indeterminate={indeterminateNonQuantitative}
              onCheckAllChange={onCheckAllChangeNonQuantitative}
              checkAll={checkAllNonQuantitative}
              optionCheckAll="Non-Quatiative"
              checkedList={checkedNonQuantitativeList}
              onChange={onChangeNonQuantitative}
              options={nonQuantitativeOptions}
              colSpan={8}
            />
            <UserInfoCheckbox
              indeterminate={indeterminateCumulative}
              onCheckAllChange={onCheckAllChangeCumulative}
              checkAll={checkAllCumulative}
              optionCheckAll="Quatiative-Cumilative"
              checkedList={checkedCumulativeList}
              onChange={onChangeCumulative}
              options={cumulativeOptions}
              colSpan={6}
            />
            <UserInfoCheckbox
              indeterminate={indeterminateNonCumulative}
              onCheckAllChange={onCheckAllChangeNonCumulative}
              checkAll={checkAllNonCumulative}
              optionCheckAll="Quatiative/Non-Cumilative"
              checkedList={checkedNonCumulativeList}
              onChange={onChangeNonCumulative}
              options={nonCumulativeOptions}
              colSpan={8}
            />
          </div>
          <div
            className="flex justify-between mt-7"
            style={{ color: "#252525" }}
          >
            <div className="flex gap-2" style={{ fontSize: "13px" }}>
              <p className="font-bold mt-1">From</p>
              <DatePicker
                onChange={onChangeStartDate}
                format={dateFormat}
                showToday={false}
                suffixIcon={
                  <svg viewBox="0 0 16 15" width="15" height="15">
                    <path d="M16 0H0v15h16zm-1 14H1V4h14zM1 3V1h14v2z"></path>
                    <path d="M3 6h2v2H3zm4 0h2v2H7zm4 0h2v2h-2zm0 4h2v2h-2zm-4 0h2v2H7zm-4 0h2v2H3z"></path>
                  </svg>
                }
              />
              <p className="font-bold mt-1">from</p>
              <DatePicker
                onChange={onChangeEndDate}
                format={dateFormat}
                showToday={false}
                suffixIcon={
                  <svg viewBox="0 0 16 15" width="15" height="15">
                    <path d="M16 0H0v15h16zm-1 14H1V4h14zM1 3V1h14v2z"></path>
                    <path d="M3 6h2v2H3zm4 0h2v2H7zm4 0h2v2h-2zm0 4h2v2h-2zm-4 0h2v2H7zm-4 0h2v2H3z"></path>
                  </svg>
                }
              />
            </div>
            <div className="flex">
              <input
                type="number"
                value={userId}
                placeholder="User ID"
                onChange={handleChangeUserID}
                className="border rounded border-black p-1"
                style={{ width: "150px", height: "25px", fontSize: "12px" }}
              />
              <button
                className="border-none rounded-full ml-2"
                style={{
                  backgroundColor: "#afaeae",
                  fontSize: "12px",
                  color: "#605e5e",
                  width: "85px",
                  height: "25px",
                }}
                onClick={handleSearch}
              >
                Search
              </button>
            </div>
          </div>
          <Table
            style={{
              borderCollapse: "separate",
              tableLayout: "fixed",
              width: "100%",
              marginTop: "20px",
            }}
            columns={userInfoCols}
            dataSource={userInfo}
            pagination={false}
            size="small"
            bordered={false}
            className="user-info"
          />
        </Col>
        <Col xs={1} sm={1} md={1} lg={1} xl={2} />
      </Row>
    </div>
  );
};

export default UserInfo;
