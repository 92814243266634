import React from "react";
import "./stake.css";

const DurationStaking = ({
  duration,
  handleClick,
  handleOpenPopup,
  stakingSetting,
  lockedUpDuration,
  stakingData,
  selfData,
  rankAndLimitsSettings,
  isLogin,
  showModalLogin,
}) => {
  return (
    <>
      <p style={{ fontSize: "30px" }} className="font-bold my-4">
        USDT STAKING
      </p>
      <div
        className=" grid grid-cols-12 text-black"
        style={{ fontSize: "12px", backgroundColor: "#ededed" }}
      >
        <div className="col-span-6 border-none p-2">
          <p>Stake Period</p>
        </div>
        <div className="col-span-2 border-none p-2">
          <p>Daily Rate Return</p>
        </div>
        <div className="col-span-2 border-none p-2">
          <p>Min. Stake Amount</p>
        </div>
        <div className="col-span-2 border-none p-2 text-transparent">
          <p>Action</p>
        </div>
      </div>
      <div className=" grid grid-cols-12 my-4" style={{ fontSize: "12px" }}>
        <div className="col-span-6">
          <div className="inline">
            {lockedUpDuration.map((data, index) => (
              <button
                key={index}
                className="border box-border bg-transparent rounded-md w-16 h-8 mr-5 my-2 border-white"
                style={{
                  fontSize: "11px",
                  backgroundColor:
                    duration === data ? "#ededed" : "transparent",
                  color: duration === data ? "black" : "#ededed",
                }}
                onClick={(e) => handleClick(e, data)}
              >
                {data}
              </button>
            ))}
          </div>
        </div>
        {(stakingData || [])
          .filter((data) => data.period === duration)
          .map((selectedStake, index) => (
            <div key={index} className="col-span-4">
              <div className="grid grid-cols-4">
                <div className="col-span-2">
                  <p className="font-semibold my-2 px-3 custom-text-sm">
                    {selectedStake.apr}%
                  </p>
                </div>
                <div className="col-span-2">
                  <p className="font-semibold my-2 px-3 custom-text-sm">
                    {
                      rankAndLimitsSettings[
                        `baseStakeLimitLv${selfData.rank || 1}`
                      ]
                    }
                  </p>
                </div>
              </div>
            </div>
          ))}

        <div className="col-span-2 mr-4">
          <button
            className="text-white my-2 custom-button"
            style={{ letterSpacing: "0.1rem", fontSize: "16px" }}
            onClick={isLogin ? handleOpenPopup : showModalLogin}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default DurationStaking;
