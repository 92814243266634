import React, { useMemo } from "react";
import { Row, Col } from "antd";

const SidebarMenu = ({ items = [], activeKey, setActiveKey }) => {
  const renderMenuItems = useMemo(() => {
    return items.map((item) => {
      return (
        <div
          onClick={() => setActiveKey(item.key)}
          key={item.key}
          style={{
            color: activeKey === item.key ? "white" : "black",
            backgroundColor: activeKey === item.key ? "#605E5E" : "#EDEDED",
            fontSize: "15px",
            fontWeight: 600,
            height: "auto",
            width: "100%",
            minHeight: "34px",
          }}
          className="border rounded-md flex items-center justify-center cursor-pointer tracking-wide"
        >
          {item.name}
        </div>
      );
    });
  }, [activeKey, items, setActiveKey]);

  return (
        <div className="flex gap-2 justify-center my-5">{renderMenuItems}</div>
      
  );
};

export default SidebarMenu;
