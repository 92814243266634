import axios from "axios";
axios.defaults.baseURL =
  process.env.REACT_APP_PUBLIC_API_URL;

export default class AdminRankAndLimitsApi {
  static async updateRankAndLimitsSetting(rankAndLimitsSetting) {
    const res = await axios.post("/admin/setting/rank-and-limits", rankAndLimitsSetting);
    return res;
  }

  static async getRankAndLimitsSetting() {
    const res = await axios.get("/admin/setting/rank-and-limits");
    return res;
  }
}
