import React from "react";
import { Controller } from "react-hook-form";
import SelectController from "../../../../../components/Select/SelectController";
import { data } from "autoprefixer";

const SelectReturnFrequency = ({ name, sortOptions, control }) => {
  return (
    <div className="flex gap-1">
      <p style={{ fontSize: "13px" }}>Every</p>
      <Controller
        name={name}
        render={({ field }) => (
          <SelectController
            field={field}
            sortOptions={sortOptions}
            size="small"
            style={{ width: "90px", height: "25px" }}
          />
        )}
        control={control}
        defaultValue={data[name]}
      />
      <p style={{ fontSize: "13px" }}>Days</p>
    </div>
  );
};

export default SelectReturnFrequency;
