import { useMemo } from "react";
import './profileMenu.css';


const ProfileMenu = ({ items = [], activeKey, setActiveKey }) => {

  const renderMenuItems = useMemo(() => {
    return items.map((item) => {
      return (
        <div className={`cursor-pointer pt-6 mx-6`}
          onClick={() => setActiveKey(item.key)} key={item.key}
        >
          <div
            className="text-white font-bold pb-1" style={{fontSize: '20px'}}
          >{item.name}
          </div>
          <div className={`${activeKey === item.key ? 'choose-item-underlined' : ''}`}></div>
        </div>
      )
    })
  }, [activeKey, items, setActiveKey]);

  return (
    <div className="flex flex-row">
      {renderMenuItems}
    </div>
  )
}

export default ProfileMenu;
