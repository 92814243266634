import { atom, selector } from "recoil";

export const activeSection = atom({
  key: "activeSection",
  default: "home",
});

export const currentSection = selector({
  key: "currentSection", // unique ID (with respect to other atoms/selectors)
  get: ({ get }) => {
    const section = get(activeSection);

    return section;
  },
});
