import { Col, Row, message, Modal } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import styled from "styled-components";
import AdminRankAndLimitsApi from "../../api/admin/admin-rank-and-limits";
import StakingApi from "../../api/staking";
import Deposit from "../../components/Deposit";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar";
import Withdraw from "../../components/Withdraw";
import { convertFormDataToArray } from "../../utils/stake";
import UserApi from "./../../api/user";
import CardReturnCalc from "./components/CardReturnCalc";
import DurationStaking from "./components/DurationStaking";
import MyStake from "./components/MyStake";
import PopupStake from "./components/PopupStake";
import "./components/stake.css";
import { sortStakeOptions } from "./components/stakingData";
import AlertPopup from "./components/AlertPopup";
import LoginPopup from "../../components/Navbar/components/LoginPopup";
import SignUpPopup from "../../components/Navbar/components/SignUpPopup";
import ForgotPassPopup from "../../components/Navbar/components/ForgotPassPopup";
import AuthApi from "../../api/auth";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import useLocalStorage from "../../hooks/localStorage.hook";
import ResetPasswordPopup from "../../components/Navbar/components/ResetPassPopup";
import { update } from "lodash";

const Container = styled.div`
  position: relative;
  overflow: hidden;
  z-index: 0;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    z-index: -1;
  }
`;

const Stake = ({ title }) => {
  const queryClient = useQueryClient();
  const isLogin = localStorage.getItem("authToken");

  const [open, setOpen] = useState(false);
  const [duration, setDuration] = useState();
  const [stakeAmount, setStakeAmount] = useState(0);
  const [stakeUnlockDateOption, setStakeUnlockDateOption] = useState(
    sortStakeOptions[0].value
  );
  const [availableBalance, setAvailableBalance] = useState(0);
  const [stakingSetting, setStakingSetting] = useState({});
  const [rankAndLimitsSettings, setRankAndLimitsSettings] = useState({});
  const [isDepositModalOpen, setIsDepositModalOpen] = useState(false);
  const [isWithdrawModelOpen, setIsWithdrawModelOpen] = useState(false);
  const [selfData, setSelfData] = useState({});
  const [dailyReturnRate, setDailyReturnRate] = useState(1.36);

  const [, setAuthTokens] = useLocalStorage("authToken", "");
  const [user, setUser] = useLocalStorage("user", "");
  const [errorMessageEmail, setErrorMessageEmail] = useState();
  const [searchParams] = useSearchParams();
  const referralCode = searchParams.get("referralCode");
  const [isModalOpenLogin, setIsModalOpenLogin] = useState(false);
  const [isModalOpenRegister, setIsModalOpenRegister] = useState(false);
  const [isModalOpenForgotPass, setIsModalOpenForgotPass] = useState(false);
  const action = searchParams.get("action");
  const token = searchParams.get("token");
  const otp = searchParams.get("otp");

  const lockedUpDuration = useMemo(() => {
    const keys = Object.keys(stakingSetting).filter((key) =>
      key.includes("periodLine")
    );

    return keys.map((key) => stakingSetting[key]);
  }, [stakingSetting]);

  useEffect(() => {
    if (lockedUpDuration) {
      setDuration(lockedUpDuration[0]);
    }
  }, [lockedUpDuration]);

  const stakingData = useMemo(() => {
    const object = convertFormDataToArray(stakingSetting);
    return object;
  }, [stakingSetting]);

  const {
    isLoading: availableBalanceLoading,
    refetch: refetchAvailableBalance,
  } = useQuery("availableBalance", UserApi.getSelfAccountBalance, {
    onSuccess: (res) => {
      setAvailableBalance(res.data.availableBalance);
    },
    onError: (error) => {
      message.error("Error while Getting available balance");
    },
    enabled: isLogin ? true : false,
  });

  const { isLoading: stakingSettingLoading } = useQuery(
    "stakingSetting",
    StakingApi.stakingSetting,
    {
      onSuccess: (res) => {
        const resData = res.data;
        setStakingSetting(resData);
      },
      onError: (error) => {
        message.error("Error while Getting staking setting");
      },
    }
  );

  const { mutate: postNewStake, isLoading: postNewStakeLoading } = useMutation(
    StakingApi.postNewStake,
    {
      onSuccess: (res) => {
        message.success("Staking Success");
        setTimeout(() => {
          queryClient.refetchQueries("getAllSelfStake");
        }, 100);
      },
      onError: (error) => {
        message.error("Error while Staking");
      },
      enabled: isLogin ? true : false,
    }
  );

  const { isLoading: rankAndLimitsLoading } = useQuery(
    "rankAndLimits",
    async () => {
      return await AdminRankAndLimitsApi.getRankAndLimitsSetting();
    },
    {
      onSuccess: (res) => {
        setRankAndLimitsSettings(res.data);
        setStakeAmount(res.data[`baseStakeLimitLv${selfData?.rank || 1}`]);
      },
      onError: (error) => {
        message.error("Error while Getting rank and limits");
      },
    }
  );

  const { isLoading: getSelfLoading } = useQuery(
    "getSelf",
    async () => {
      return await UserApi.getSelf();
    },
    {
      onSuccess: (res) => {
        setSelfData(res.data);
      },
      onError: (error) => {
        message.error("Error while Getting user");
      },
      enabled: isLogin ? true : false,
    }
  );

  useEffect(() => {
    document.title = title;
  }, [title]);

  const handleClick = (_e, data) => {
    setDuration(data);
  };

  const handleOpenPopup = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSetStakeUnlockDateOption = (value) => {
    setStakeUnlockDateOption(value);
  };

  const handlePostNewStake = () => {
    if (stakeAmount < 10 || stakeAmount > availableBalance) {
      return message.error("Invalid Staking Amount");
    }
    postNewStake({
      stakeAmount,
      lockupDuration: duration,
      stakeUnlockDateOption: stakeUnlockDateOption,
    });
    //after run postNewStake, reload available balance
    refetchAvailableBalance();
    //reload available balance
    // queryClient.refetchQueries("availableBalance");
    
  };

  const handleOpenDepositModal = () => {
    if (isLogin) {
      setIsDepositModalOpen(true);
    } else {
      setIsModalOpenLogin(true);
    }
  };

  const handleOpenWithdrawModal = () => {
    if (isLogin) {
      setIsWithdrawModelOpen(true);
    } else {
      setIsModalOpenLogin(true);
    }
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    criteriaMode: "all",
    defaultValues: {
      email: "",
      password: "",
      name: "",
      referralCode: referralCode || "",
    },
  });

  const { isLoading: signUpLoading, mutate: signUp } = useMutation(
    (data) =>
      AuthApi.register(data.name, data.email, data.password, data.referralCode),
    {
      onSuccess: (response) => {
        const resAuthTokens = response.data.authTokens;
        const resUser = response.data.user;
        setAuthTokens(resAuthTokens);
        setUser(resUser);
        // refresh page
        window.location.reload();
        return message.success("Register success");
      },
      onError: (error) => {
        console.log(error);
        if (error.response.status === 500) {
          message.error("Internal Server error");
        } else {
          if (error.response.data?.message === "User already exists") {
            setErrorMessageEmail(
              "This email is already in use. If you have lost your password, click on the “forgot password” option to retrieve it."
            );
          }
          message.error(
            error.response.data?.message || "Internal Server error"
          );
        }
        reset({
          email: "",
          password: "",
          referralCode: "",
          name: "",
          confirmPassword: "",
        });
      },
    }
  );

  const onRegisterSubmit = (data) => {
    if (data.password === data.confirmPassword) {
      signUp({ ...data });
    }
  };

  const onLoginSubmit = async (data) => {
    try {
      const response = await AuthApi.login(data.email, data.password);
      if (response.status === 200) {
        const resAuthTokens = response.data.authTokens;
        const resUser = response.data.user;

        setAuthTokens(resAuthTokens);
        setUser(resUser);
        // refresh page
        window.location.reload();
        return message.success("Login success");
      }
    } catch (error) {
      if (error.response.status === 500) {
        message.error("Internal Server error");
      } else {
        message.error(error.response.data?.message || "Internal Server error");
      }

      reset({
        email: "",
        password: "",
      });
    }
  };

  const onForgotPasswordSubmit = async (data) => {
    try {
      const response = await AuthApi.forgotPassword(data.email);
      if (response.status === 200) {
        // refresh page
        message.success("Please check your email to reset your password");
      }
    } catch (error) {
      console.log({ error });
      if (error.response.status === 500) {
        message.error("Internal Server error");
      } else {
        message.error(error.response.data?.message || "Internal Server error");
      }
    }
  };

  const showModalLogin = () => {
    setIsModalOpenLogin(true);
    reset({
      email: "",
      password: "",
    });
  };
  const handleCancelLogin = () => {
    setIsModalOpenLogin(false);
  };

  const handleCancelForgotPass = () => {
    setIsModalOpenForgotPass(false);
  };

  const handleCancelRegister = () => {
    setIsModalOpenRegister(false);
  };

  return (
    <Container>
      <Navbar />
      {isLogin && (
        <>
          <Deposit
            isModalOpen={isDepositModalOpen}
            setIsModalOpen={setIsDepositModalOpen}
            refetch={refetchAvailableBalance}
          />
          <Withdraw
            isModalOpen={isWithdrawModelOpen}
            setIsModalOpen={setIsWithdrawModelOpen}
            refetch={refetchAvailableBalance}
          />
        </>
      )}

      <div className="flex-1">
        <Row className="mb-12 text-white" justify="center">
          <Col xs={23} sm={23} md={23} lg={23} xl={20}>
            <div className="text-center mt-12">
              <p style={{ fontSize: "12px" }}>SPOT WALLET</p>
              <p style={{ fontSize: "44px" }} className="font-semibold">
                {availableBalance} USDT
              </p>
              <div
                className="flex justify-center items-center gap-10 my-5"
                style={{ fontSize: "14px" }}
              >
                <button
                  className="font-bold border-none box-border w-32 h-8 text-black"
                  style={{ backgroundColor: "#ededed" }}
                  onClick={handleOpenDepositModal}
                >
                  DEPOSIT
                </button>
                <button
                  className="font-bold border-none box-border w-32 h-8 text-black"
                  style={{ backgroundColor: "#ededed" }}
                  onClick={handleOpenWithdrawModal}
                >
                  WITHDRAW
                </button>
              </div>
            </div>
            <DurationStaking
              stakingSetting={stakingSetting}
              duration={duration}
              handleClick={handleClick}
              handleOpenPopup={handleOpenPopup}
              lockedUpDuration={lockedUpDuration}
              stakingData={stakingData}
              rankAndLimitsSettings={rankAndLimitsSettings}
              selfData={selfData}
              isLogin={isLogin}
              showModalLogin={showModalLogin}
            />
            <CardReturnCalc
              duration={duration}
              stakeAmount={stakeAmount}
              setStakeAmount={setStakeAmount}
              rankAndLimitsSettings={rankAndLimitsSettings}
              selfData={selfData}
              dailyReturnRate={dailyReturnRate}
            />
          </Col>
        </Row>
        <PopupStake
          open={open}
          duration={duration}
          setDuration={setDuration}
          handleCancel={handleCancel}
          stakeAmount={stakeAmount}
          setStakeAmount={setStakeAmount}
          stakeUnlockDateOption={stakeUnlockDateOption}
          handleSetStakeUnlockDateOption={handleSetStakeUnlockDateOption}
          availableBalance={availableBalance}
          handlePostNewStake={handlePostNewStake}
          rankAndLimitsSettings={rankAndLimitsSettings}
          selfData={selfData}
          lockedUpDuration={lockedUpDuration}
          isLogin={isLogin}
        />

        <LoginPopup
          isModalOpenLogin={isModalOpenLogin}
          handleCancelLogin={handleCancelLogin}
          handleSubmit={handleSubmit}
          onLoginSubmit={onLoginSubmit}
          control={control}
          errors={errors}
          setIsModalOpenLogin={setIsModalOpenLogin}
          setIsModalOpenRegister={setIsModalOpenRegister}
          isModalOpenForgotPass={isModalOpenForgotPass}
          setIsModalOpenForgotPass={setIsModalOpenForgotPass}
        />

        <SignUpPopup
          isModalOpenRegister={isModalOpenRegister}
          handleCancelRegister={handleCancelRegister}
          handleSubmit={handleSubmit}
          onRegisterSubmit={onRegisterSubmit}
          control={control}
          errors={errors}
          setIsModalOpenLogin={setIsModalOpenLogin}
          setIsModalOpenRegister={setIsModalOpenRegister}
          signUpLoading={signUpLoading}
          errorMessageEmail={errorMessageEmail}
          watch={watch}
          isModalOpenForgotPass={isModalOpenForgotPass}
          setIsModalOpenForgotPass={setIsModalOpenForgotPass}
        />
        <ForgotPassPopup
          isModalOpenRegister={isModalOpenRegister}
          handleCancelRegister={handleCancelRegister}
          handleSubmit={handleSubmit}
          onRegisterSubmit={onRegisterSubmit}
          control={control}
          errors={errors}
          setIsModalOpenLogin={setIsModalOpenLogin}
          setIsModalOpenRegister={setIsModalOpenRegister}
          signUpLoading={signUpLoading}
          isModalOpenForgotPass={isModalOpenForgotPass}
          setIsModalOpenForgotPass={setIsModalOpenForgotPass}
          onForgotPasswordSubmit={onForgotPasswordSubmit}
          handleCancelForgotPass={handleCancelForgotPass}
        />
        <ResetPasswordPopup otp={otp} token={token} action={action} />
      </div>
      {isLogin && <MyStake />}
      <Footer />
    </Container>
  );
};

export default Stake;
