import React, { useEffect } from "react";
import AdminNavbar from "../../components/Navbar/AdminNavbar";
import { Col, Row } from "antd";
import Cashflow from "./components/Cashflow";
import StakerBalance from "./components/StakerBalance";
import WalletBalance from "./components/WalletBalance";
import TransactionHistory from "./components/TransactionHistory";
import "./components/transactionHistory.css";

const UserWallet = ({ title }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);
  
  return (
    <div className="xl:overflow-hidden sm:overflow-auto">
      <AdminNavbar />
      <Row gutter={16} justify="center">
        <Col xs={22} sm={22} md={22} lg={22} xl={8}>
          <WalletBalance />
          <StakerBalance />
          {/* <Cashflow /> */}
          <TransactionHistory />
        </Col>
      </Row>
    </div>
  );
};

export default UserWallet;
