import React from "react";
import { Controller } from "react-hook-form";
import { passwordRegExp } from "../../../utils/regFormat";

const InputPass = ({ control, name, labelText, newPass }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={
        newPass
          ? {
              pattern: {
                value: passwordRegExp,
                message:
                  "Password must have a minimum of 8 characters with uppercase, lowercase, numbers and symbols.",
              },
            }
          : {}
      }
      render={({ field }) => (
        <div class="relative z-0 w-full mt-6 group">
          <input
            {...field}
            type="password"
            name={name}
            id={name}
            class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=" "
            required
          />
          <label
            for={name}
            class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            {labelText}
          </label>
        </div>
      )}
    />
  );
};

export default InputPass;
