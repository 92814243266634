import { Col, Row } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const AdminNavbar = () => {
  const navigate = useNavigate();
  //get link name from url
  const linkName = window.location.pathname.split("/")[2];
  return (
    <div
      className="flex justify-center gap-52 py-8 text-black mx-3"
      style={{ fontSize: "15px", backgroundColor: "#a0a09f" }}
    >
      <button
        onClick={() => navigate("/admin/dashboard")}
        style={{ color: `${linkName === "dashboard" && "white"}` }}
      >
        Dashboard
      </button>
      {/* <button
              onClick={() => navigate("/admin/user_info")}
              style={{ color: `${linkName === "user_info" && "#00973a"}` }}
            >
              User Info
            </button>
            <button
              onClick={() => navigate("/admin/user_activities")}
              style={{
                color: `${linkName === "user_activities" && "#00973a"}`,
              }}
            >
              User Activities
            </button> */}
      <button
        onClick={() => navigate("/admin/user_wallet")}
        style={{ color: `${linkName === "user_wallet" && "white"}` }}
      >
        Main Wallet
      </button>
      <button
        onClick={() => navigate("/admin/setting")}
        style={{ color: `${linkName === "setting" && "white"}` }}
      >
        Setting
      </button>
    </div>
  );
};

export default AdminNavbar;
