import { InputNumber } from "antd";
import React from "react";

const CardReturnCalc = ({
  duration,
  rankAndLimitsSettings,
  selfData,
  stakeAmount,
  setStakeAmount,
  dailyReturnRate,
}) => {
  // const [annualReturnRate, setAnnualReturnRate] = useState(ANR);
  const apr = 5;
  return (
    <div
      className="border border-slate-300 rounded-md p-4 styled-card flex flex-col gap-4 bg-white text-black"
      style={{ margin: "40px auto" }}
    >
      <p style={{ fontSize: "14px" }} className="font-semibold">
        Return Calculator
      </p>
      <div className="flex flex-wrap gap-4 justify-between">
        <div className="flex flex-col">
          <p style={{ fontSize: "23px", fontWeight: "400", width:'100px' }}>{duration}</p>
          <p style={{ fontSize: "10px" }}>STAKED PERIOD</p>
        </div>
        <div className="flex flex-col">
          {/* <p className="border border-black rounded-md bg-white p-1">{stakeAmount}</p> */}
          <InputNumber
            value={stakeAmount}
            min={
              rankAndLimitsSettings[`baseStakeLimitLv${selfData?.rank || 1}`] ||
              0
            }
            max={
              rankAndLimitsSettings[`stakeLimitCapLv${selfData?.rank || 1}`] ||
              0
            }
            onChange={(value) => {
              setStakeAmount(value);
            }}
          />
          <p style={{ fontSize: "10px" }} className="pt-1">
            STAKE AMOUNT
          </p>
        </div>
        <div className="flex flex-col">
          <p style={{ fontSize: "23px", fontWeight: "400" }}>
            {dailyReturnRate}%
          </p>
          <p style={{ fontSize: "10px" }}>DAILY RETURN RATE</p>
        </div>
        <div className="flex flex-col">
          <p style={{ fontSize: "23px", fontWeight: "400" }}>
            {((stakeAmount * apr) / 365).toFixed(2)} USDT
          </p>
          <p style={{ fontSize: "10px" }}>DAILY INCOME</p>
        </div>
        <div className="flex flex-col">
          <p style={{ fontSize: "23px", fontWeight: "400" }}>
            {stakeAmount * apr} USDT
          </p>
          <p style={{ fontSize: "10px" }}>PERIOD INCOME</p>
        </div>
      </div>
      {/* <p style={{ fontSize: "11px" }}>
        *Current return rate does not represent the past and future return rate.
        To find out more{" "}
        <a style={{ color: "#2B3AFE" }} href="./">click here.</a>{" "}
      </p> */}
    </div>
  );
};

export default CardReturnCalc;
