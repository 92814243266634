import React from "react";
import { Modal, Typography, Button } from "antd";
import { ErrorMessage } from "@hookform/error-message";
import { Controller } from "react-hook-form";
import InputModal from "./InputModal";
import InputPass from "./InputPass";
import { nameRegExp } from "../../../utils/regFormat";

const SignUpPopup = ({
  isModalOpenRegister,
  handleCancelRegister,
  handleSubmit,
  onRegisterSubmit,
  control,
  errors,
  setIsModalOpenLogin,
  setIsModalOpenRegister,
  signUpLoading,
  errorMessageEmail,
  watch,
  disabledOtp,
  getOtp,
}) => {
  return (
    <Modal
      open={isModalOpenRegister}
      onCancel={handleCancelRegister}
      closeIcon={false}
      footer={null}
      style={{ width: "460px" }}
      centered={true}
    >
      <div>
        <form
          onSubmit={handleSubmit(onRegisterSubmit)}
          className="flex flex-col p-5"
        >
          <Typography className="text-4xl font-bold m-auto pb-3">
            Sign Up
          </Typography>
          <Controller
            name="name"
            rules={{
              pattern: {
                value: nameRegExp,
                message: "Invalid name format",
              },
            }}
            render={({ field }) => (
              <div class="relative z-0 w-full mt-6 group">
                <input
                  {...field}
                  type="text"
                  name="name"
                  id="name"
                  class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required={true}
                />
                <label
                  for="name"
                  class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8"
                >
                  Enter Name
                </label>
              </div>
            )}
            control={control}
          />
          <InputModal
            control={control}
            name="email"
            placeholder="Enter Email"
            type="email"
          />
          {errorMessageEmail && (
            <p className="text-red-500 text-xs italic">{errorMessageEmail}</p>
          )}
          <ErrorMessage
            errors={errors}
            name="email"
            render={({ message }) => (
              <p className="text-red-500 text-xs italic">{message}</p>
            )}
          />
          <InputModal
            control={control}
            name="referralCode"
            placeholder="Enter Sign Up Code (if any)"
            required={false}
          />
          {/* <div class="relative z-0 w-full mb-6 group">
            <input
              name="referralCode"
              id="referralCode"
              class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              disabled
              value={referralCode}
            />
            <label
              for="referralCode"
              class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8"
            >
              Referral Code
            </label>
          </div> */}
          <InputPass
            labelText={"Enter Password"}
            control={control}
            name="password"
            placeholder="Enter Password"
            errors={errors}
            newPass={true}
          />
          <ErrorMessage
            errors={errors}
            name="password"
            render={({ message }) => (
              <p className="text-red-500 text-xs italic">{message}</p>
            )}
          />
          <InputPass
            labelText="Enter Confirm Password"
            control={control}
            name="confirmPassword"
            placeholder="Enter Confirm Password"
            errors={errors}
            newPass={true}
          />
          {watch("confirmPassword")
            ? watch("confirmPassword") !== watch("password") && (
                <p className="text-red-500 text-xs italic">
                  Password does not match
                </p>
              )
            : null}
          <ErrorMessage
            errors={errors}
            name="confirmPassword"
            render={({ message }) => (
              <p className="text-red-500 text-xs italic">{message}</p>
            )}
          />
          <div className="flex flex-row gap-2">
            <InputModal
              control={control}
              name="otp"
              placeholder="Enter OTP"
              required={false}
              disable={disabledOtp}
            />
            <button
              type="button"
              id="getOtp"
              style={{
                height: "36px",
                backgroundColor: "#2c2c2c",
                transition: "all 0.2s ease, visibility 0s",
                fontSize: "15px",
              }}
              className="font-semibold text-white rounded-lg w-1/3 m-auto"
              onClick={getOtp}
            >
              Get OTP
            </button>
          </div>

          <div className="flex gap-1 text-sm py-3">
            <Typography>Already have account.</Typography>
            <Typography
              className="italic text-blue-500 cursor-pointer"
              onClick={() => {
                setIsModalOpenLogin(true);
                setIsModalOpenRegister(false);
              }}
            >
              Login here
            </Typography>
          </div>
          <button
            type="submit"
            id="register"
            style={{
              height: "36px",
              backgroundColor: "#2c2c2c",
              transition: "all 0.2s ease, visibility 0s",
              fontSize: "15px",
            }}
            className="col-start-5 font-semibold text-white rounded-lg m-auto w-full"
          >
            REGISTER
          </button>
        </form>
      </div>
    </Modal>
  );
};

export default SignUpPopup;
