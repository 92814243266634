import axios from "axios";
axios.defaults.baseURL =
  process.env.REACT_APP_PUBLIC_API_URL;

export default class AdminRewardsAndBonusApi {
  static async updateRewardsAndBonusSetting(rewardsAndBonusSetting) {
    const res = await axios.post("/admin/setting/reward-and-bonus", rewardsAndBonusSetting);
    return res;
  }

  static async getRewardsAndBonusSetting() {
    const res = await axios.get("/admin/setting/reward-and-bonus");
    return res;
  }
}
