import axios from "axios";
import { getBearerToken } from "../../utils/localStorage";
import { useMutation } from "react-query";
axios.defaults.baseURL =
  process.env.REACT_APP_PUBLIC_API_URL;

// write a hook to use this api in react-query with upload progress
export const useMutationSetHeroSectionImage = (onSuccess, onError) => {
  const token = getBearerToken();
  return useMutation(
    (data) => {
      return axios.post(
        "/admin/frontend/hero-section-image",
        {
          image: data.image,
          fileName: data.fileName,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: (response) => {
        onSuccess(response.data);
      },
      onError: (error) => {
        onError(error);
      },
    }
  );
};

export const useMutationSetInvestSectionImage = (onSuccess, onError) => {
  const token = getBearerToken();
  return useMutation(
    (data) => {
      return axios.post(
        "/admin/frontend/invest-section-image",
        {
          image: data.image,
          fileName: data.fileName,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: (response) => {
        onSuccess(response.data);
      },
      onError: (error) => {
        onError(error);
      },
    }
  );
};

export const useMutationSetHistorySectionImage = (onSuccess, onError) => {
  const token = getBearerToken();
  return useMutation(
    (data) => {
      return axios.post(
        "/admin/frontend/history-section-image",
        {
          image: data.image,
          fileName: data.fileName,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: (response) => {
        onSuccess(response.data);
      },
      onError: (error) => {
        onError(error);
      },
    }
  );
};

export const useMutationSetFreqEnquirySectionImage = (onSuccess, onError) => {
  const token = getBearerToken();
  return useMutation(
    (data) => {
      return axios.post(
        "/admin/frontend/freq-enquiry-section-image",
        {
          image: data.image,
          fileName: data.fileName,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: (response) => {
        onSuccess(response.data);
      },
      onError: (error) => {
        onError(error);
      },
    }
  );
};
