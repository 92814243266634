import { Checkbox, Col, Row } from "antd";
import React from "react";
import './checkbox.css'

const UserInfoCheckbox = ({indeterminate, onCheckAllChange, checkAll, optionCheckAll, checkedList, onChange, options, colSpan}) => {
  return (
    <div>
      <Checkbox
        indeterminate={indeterminate}
        onChange={onCheckAllChange}
        checked={checkAll}
        style={{ fontSize: "13px", fontWeight: 650, color: "#000000" }}
        className="styled-checkbox"
      >
        {optionCheckAll}
      </Checkbox>
      <Checkbox.Group
        style={{ width: "100%" }}
        value={checkedList}
        onChange={onChange}
      >
        <Row>
          {options.map((option, index) => (
            <Col span={colSpan} key={index}>
              <Checkbox
                value={option}
                style={{ fontSize: "10px", color: "#000000" }}
                className="styled-checkbox"
              >
                {option}
              </Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
    </div>
  );
};

export default UserInfoCheckbox;
