import axios from "axios";
import store from "../store";
import { getBearerToken } from "../utils/localStorage";

axios.defaults.baseURL = process.env.REACT_APP_PUBLIC_API_URL;

export default class AuthApi {
  static async login(email, password) {
    const res = await axios.post("/auth/login", {
      email,
      password, 
    });
    return res;
  }

  static async register(name, email, password, referralCode,otp) {
    const res = await axios.post("/auth/register", {
      name,
      email,
      password,
      referralCode,
      otp,
    });
    return res;
  }

  static async refreshToken() {
    const user = store.getState().user?.user;
    const res = await axios.post(
      "/auth/refresh",
      {},
      {
        headers: {
          authorization: user.token,
        },
      }
    );
    return res;
  }

  static async sendEmailVerification() {
    const token = getBearerToken();
    const res = await axios.post(
      "/auth/send-email-verification",
      {},
      {
        headers: {
          authorization: token,
        },
      }
    );
    return res;
  }

  static async sendConfirmEmail(email) {
    const res = await axios.post("/auth/send-email-verification-sign-up", {
      email,
    });
    return res;
  }

  static async forgotPassword(email) {
    const res = await axios.post("/auth/forgot-password", {
      email,
    });
    return res;
  }

  static async resetPassword(token, otp, password) {
    const res = await axios.post("/auth/reset-password", {
      token,
      otp,
      password,
    });
    return res;
  }

  static async checkToken(tokenType, token) {
    const res = await axios.get(`/auth/check-token/${tokenType}/${token}`);
    return res;
  }

  static async verifyPassword(password) {
    const token = getBearerToken();
    const res = await axios.post(
      "/auth/check-password",
      { password },
      {
        headers: {
          authorization: token,
        },
      }
    );
    return res;
  }
}
