import { Button, Col, Pagination, Row, Table, message } from "antd";
import React, { useState, useEffect } from "react";
import { SelectMenu } from "../../components/Select";
import Widget from "../../components/Widget";
import BalanceLayout from "./components/walletComponents/BalanceLayout";
import "./components/walletComponents/wallet.css";
import {
  sortOptions,
  transactionColumns,
} from "./components/walletComponents/walletItems";
import "./components/walletComponents/wallet.css";
import { useQuery } from "react-query";
import WalletApi from "../../api/wallet";

const MyWallet = ({ accountInfo }) => {
  const [transactionData, setTransactionData] = useState([]);
  const { isLoading, refetch: getAllTransactionHistory } = useQuery(
    "transactionHistory",
    async () => {
      return await WalletApi.getTransactionHistory();
    },
    {
      onSuccess: (res) => {
        setTransactionData(res.data);
      },
    },
    {
      onError: (error) => {
        console.log(error);
        message.error("Error while Getting Transaction History");
      },
    }
  );

  useEffect(() => {
    if (!isLoading) {
      getAllTransactionHistory();
    }
  }, [getAllTransactionHistory, isLoading]);

  const [currentSortOption, setCurrentSortOption] = useState(
    sortOptions[0].value
  );
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 20;
  const totalItems = transactionData.length;

  const handleChange = (value) => {
    setCurrentSortOption(value);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * pageSize + 1;
  const endIndex = Math.min(currentPage * pageSize, totalItems);

  const sortedData =
    currentSortOption === "Latest First"
      ? [...transactionData].sort((a, b) => new Date(b.date) - new Date(a.date))
      : [...transactionData].sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );

  const currentPageData = sortedData.slice(startIndex - 1, endIndex);

  let locale = {
    emptyText: (
      <p className="text-center text-black">You do not have any transaction</p>
    ),
  };

  return (
    <>
      <Row gutter={16} justify={"center"} className="mt-3">
        <Col xs={22} sm={22} md={22} lg={22} xl={17}>
          <Widget className="my-2 border-2 border-slate-300 rounded-none bg-white">
            <BalanceLayout accountInfo={accountInfo} />
          </Widget>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={11}>
          <Widget className="border-0 rounded-none mt-10 bg-white">
            <>
              <div className="flex justify-between">
                <p className="pb-2">Transaction History</p>
                {/* <div className="flex gap-2">
                  <p className="pt-1">Sort by:</p>
                  <SelectMenu
                    defaultValue={sortOptions[0].value}
                    handleChange={handleChange}
                    options={sortOptions}
                    width="w-32"
                  />
                </div> */}
              </div>
              <Table
                style={{
                  borderCollapse: "separate",
                  tableLayout: "fixed",
                  width: "100%",
              }}
                locale={locale}
                columns={transactionColumns}
                dataSource={currentPageData}
                pagination={false}
                size="small"
                bordered={false}
                className="wallet-table"
                loading={isLoading}
              />
              {transactionData.length !== 0 && (
                <>
                  <div className="text-left mt-3 font-bold text-xs">
                    Showing {startIndex} - {endIndex} of {totalItems}{" "}
                    transactions
                  </div>
                  <div className="flex justify-center mt-3">
                    <Button type="text" onClick={() => setCurrentPage(1)}>
                      First
                    </Button>
                    <Pagination
                      current={currentPage}
                      pageSize={pageSize}
                      total={transactionData.length} // Total number of data items
                      onChange={handlePageChange}
                    />
                    <Button
                      type="text"
                      onClick={() =>
                        setCurrentPage(
                          Math.ceil(transactionData.length / pageSize)
                        )
                      }
                    >
                      Last
                    </Button>
                  </div>
                </>
              )}
            </>
          </Widget>
        </Col>
      </Row>
    </>
  );
};

export default MyWallet;
