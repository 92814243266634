import axios from 'axios';
import { getBearerToken } from '../utils/localStorage';
axios.defaults.baseURL = process.env.REACT_APP_PUBLIC_API_URL

export default class StakingApi {
  static async postNewStake (stakingData) {
    const token = getBearerToken();
    const res = await axios.post('/staking/me/one', stakingData, {
      headers: {
        authorization: token,
      },
    });
    return res;
  }

  static async getAllSelfStake () {
    const token = getBearerToken();
    const res = await axios.get('/staking/me', {
      headers: {
        authorization: token,
      },
    });
    return res;
  }

  static async stakingSetting () {
    const res = await axios.get('/staking/setting');
    return res;
  }
}
