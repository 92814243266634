import { ErrorMessage } from "@hookform/error-message";
import { Modal, Typography } from "antd";
import React from "react";
import InputModal from "./InputModal";
import InputPass from "./InputPass";

const LoginPopup = ({
  isModalOpenLogin,
  handleCancelLogin,
  handleSubmit,
  onLoginSubmit,
  control,
  errors,
  setIsModalOpenLogin,
  setIsModalOpenRegister,
  isModalOpenForgotPass,
  setIsModalOpenForgotPass,
}) => {
  return (
    <Modal
      open={isModalOpenLogin}
      onCancel={handleCancelLogin}
      closeIcon={false}
      footer={null}
      style={{ width: "460px" }}
    >
      <div>
        <form
          onSubmit={handleSubmit(onLoginSubmit)}
          className="flex flex-col p-5"
        >
          <Typography className="text-4xl font-bold m-auto pb-3">
            Log In
          </Typography>
          <InputModal
            control={control}
            name="email"
            placeholder="Enter Email"
            type="email"
          />
          <InputPass
            labelText={"Enter Password"}
            control={control}
            name="password"
            placeholder="Enter Password"
            errors={errors}
          />
          <ErrorMessage
            errors={errors}
            name="password"
            render={({ message }) => (
              <p className="text-red-500 text-xs italic mb-2">{message}</p>
            )}
          />
          <div className="flex justify-between py-3">
            <Typography
              className="cursor-pointer italic text-blue-600"
              style={{ fontSize: "12px" }}
              onClick={() => {
                setIsModalOpenLogin(false);
                setIsModalOpenRegister(true);
                setIsModalOpenForgotPass(false);
              }} 
              >
                Don't have an account? Register
              </Typography>
            <Typography
              className="cursor-pointer italic text-blue-600"
              style={{ fontSize: "12px" }}
              onClick={() => {
                setIsModalOpenLogin(false);
                setIsModalOpenRegister(false);
                setIsModalOpenForgotPass(true);
              }}
            >
              Forgot Password
            </Typography>
          </div>

          <button
            type="submit"
            style={{
              height: "36px",
              backgroundColor: "#2c2c2c",
              transition: "all 0.2s ease, visibility 0s",
              fontSize: "15px",
            }}
            className="col-start-5 font-semibold text-white rounded-lg m-auto w-full"
          >
            LOGIN
          </button>
        </form>
      </div>
    </Modal>
  );
};

export default LoginPopup;
