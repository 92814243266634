import {
  LoadingOutlined,
  PlusOutlined
} from "@ant-design/icons";
import { Upload, message } from "antd";
import { useState } from "react";
import { imageToBase64 } from "../../../../utils/image";

const UploadImage = ({ useMutationUploadImage }) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();

  const { mutate: uploadImage } = useMutationUploadImage(
    (data) => {
      message.success("Image uploaded successfully");
      setLoading(false);
    },
    (error) => {
      message.error("Error uploading image");
      setLoading(false);
    }
  );

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );


  return (
    <Upload
      name="file"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      multiple={false}
      accept="image/*"
      customRequest={async ({ file }) => {
        setLoading(true);
        // convert file to base64 string
        const base64 = await imageToBase64(file);
        setImageUrl(base64);

        // upload image
        uploadImage({ image: base64, fileName: file.name });
      }}
    >
      {imageUrl ? (
        <img
          src={imageUrl}
          alt="background"
          style={{
            width: "100%",
          }}
        />
      ) : (
        uploadButton
      )}
    </Upload>
  );
};

export default UploadImage;
