import dayjs from "dayjs";

export const nonQuantitativeOptions = [
  "Name",
  "Rank",
  "KYC Status",
  "Email",
  "Cluster",
  "Joined Date & Time",
  "Mobile",
  "Account Status",
  "Last Active Date & Time",
];

export const cumulativeOptions = [
  "Self Turnover",
  "Group DASA",
  "Tier 2 Comm Earned",
  "Withdrawal",
  "Group Turnover",
  "APR Earned",
  "Combined Earning",
  "Difference",
  "Self DASA",
  "Tier 1 Comm Earned",
  "Deposit",
  "Referral",
];

export const nonCumulativeOptions = [
  "Available Balance",
  "Total Balance",
  "Current Stake Limit",
  "Locked Up Balance",
  "Last Active Date",
  "Used Stake Limit",
  "Holding Balance",
  "Account Status",
  "Unused Staked Limit",
];

export const userInfoCols = [
  {
    title: "User ID",
    dataIndex: "userId",
    key: "userId",
    render: (text) => <p className="text-xs" style={{color: '#2461d9'}}>{text}</p>,
  },
  {
    title: "Cluster",
    dataIndex: "cluster",
    key: "cluster",
    render: (text) => <p className="text-xs">{text}</p>,
  },
  {
    title: "Rank",
    dataIndex: "rank",
    key: "rank",
    render: (text) => <p className="text-xs">{text}</p>,
  },
  {
    title: "Joined Date",
    dataIndex: "joinedDate",
    key: "joinedDate",
    render: (text) => (
      <p className="text-xs">{dayjs(text).format("DD/MM/YY")}</p>
    ),
  },
  {
    title: "Active Stake",
    dataIndex: "activeStake",
    key: "activeStake",
    render: (text) => <p className="text-xs">{text}</p>,
  },
  {
    title: " Available Balance",
    dataIndex: "availableBalance",
    key: "availableBalance",
    render: (text) => <p className="text-xs">{text}</p>,
  },
  {
    title: " Stake Balance",
    dataIndex: "stakeBalance",
    key: "stakeBalance",
    render: (text) => <p className="text-xs">{text}</p>,
  },
  {
    title: "APR Earned",
    dataIndex: "aprEarned",
    key: "aprEarned",
    render: (text) => <p className="text-xs">{text}</p>,
  },
  {
    title: "Commission Earned",
    dataIndex: "commissionEarned",
    key: "commissionEarned",
    render: (text) => <p className="text-xs">{text}</p>,
  },
  {
    title: "Total Earning",
    dataIndex: "totalEarning",
    key: "totalEarning",
    render: (text) => <p className="text-xs">{text}</p>,
  },
  {
    title: "Total Deposit",
    dataIndex: "totalDeposit",
    key: "totalDeposit",
    render: (text) => <p className="text-xs">{text}</p>,
  },
  {
    title: "Total Withdrawal ",
    dataIndex: "totalWithdrawal",
    key: "totalWithdrawal",
    render: (text) => <p className="text-xs">{text}</p>,
  },
  {
    title: "Difference",
    dataIndex: "difference",
    key: "difference",
    render: (text) => <p className="text-xs">{text}</p>,
  },
];

export const userInfo = [
  {
    userId: "283836",
    cluster: "Jennifer Lynn (290833)",
    rank: "Master Leader ",
    joinedDate: "2023-04-28",
    activeStake: 2,
    availableBalance: "61 USDT",
    stakeBalance: "28,300 USDT",
    aprEarned: "7,619 USDT",
    commissionEarned: "3,060 USDT",
    totalEarning: "10,679 USDT",
    totalDeposit: "20,000 USDT",
    totalWithdrawal: "18,000 USDT",
    difference: "2,000 USDT",
  },
  {
    userId: "288972",
    cluster: "Jennifer Nguyen",
    rank: "Master Leader ",
    joinedDate: "2023-04-28",
    activeStake: 2,
    availableBalance: "61 USDT",
    stakeBalance: "28,300 USDT",
    aprEarned: "7,619 USDT",
    commissionEarned: "3,060 USDT",
    totalEarning: "10,679 USDT",
    totalDeposit: "20,000 USDT",
    totalWithdrawal: "18,000 USDT",
    difference: "2,000 USDT",
  },
];
