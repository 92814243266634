import { Input } from "antd";
import React from "react";
import { Controller } from "react-hook-form";

const InputPeriod = ({ name, control, style }) => {
  const handleInputBlur = (e) => {
    const { value } = e.target;
    if (value.trim() === "") {
      e.target.classList.add("red-border");
    } else {
      e.target.classList.remove("red-border");
    }
  };
  return (
    <Controller
      name={name}
      render={({ field }) => (
        <Input
          {...field}
          onBlur={(e) => {
            field.onBlur(e);
            handleInputBlur(e);
          }}
          size="small"
          style={{
            ...style,
            fontSize: "12px",
            color: "#605e5e",
            height: "25px",
            fontWeight: "lighter",
            width: "79px",
            border: "1px solid black",
          }}
          className="py-1 mx-1"
        />
      )}
      control={control}
    />
  );
};

export default InputPeriod;
