import React, { useEffect, useState } from "react";
import { Col, Row, message } from "antd";
import Widget from "../../components/Widget";
import InfoWidget1 from "./components/infoComponents/InfoWidget1";
// import InfoWidget2 from "./components/infoComponents/InfoWidget2";
// import InfoWidget3 from "./components/infoComponents/InfoWidget3";
// import { useQuery } from "react-query";
// import AdminRankAndLimitsApi from "../../api/admin/admin-rank-and-limits";

const MyInfo = () => {
  // const [rank, setRank] = useState("");

  // const { isLoading: isLoadingGetRankingAndLimits, refetch: getRankingAndLimits } = useQuery(
  //   "rankAndLimits",
  //   async () => {
  //     return await AdminRankAndLimitsApi.getRankAndLimitsSetting();
  //   },
  //   {
  //     onSuccess: (res) => {
  //       setRankAndLimitsSetting(res.data);
  //       setRank(res.data.rankDesLv1);
  //     },
  //     onError: (error) => {
  //       message.error("Error while Getting available balance");
  //     },
  //   }
  // );

  // useEffect(() => {
  //   if (!isLoadingGetRankingAndLimits) {
  //     getRankingAndLimits();
  //   }
  // }, [isLoadingGetRankingAndLimits, getRankingAndLimits]);
  return (
    <>
      <Row gutter={16} justify={"center"} className="mt-3">
        <Col xs={23} sm={23} md={23} lg={23} xl={15}>
          <Widget className="border-2 border-slate-300 rounded-none">
            <InfoWidget1 />
          </Widget>
          {/* <Widget className="rounded-none mt-2">
            <InfoWidget2 />
          </Widget> */}
        </Col>
        {/* <Col xs={23} sm={23} md={23} lg={22} xl={10}>
          <Widget className="my-2 border-2 border-slate-300 rounded-none">
            <InfoWidget3 rankAndLimitsSetting={rankAndLimitsSetting} />{" "}
          </Widget>
        </Col> */}
      </Row>
    </>
  );
};

export default MyInfo;
