import React from "react";
import { Typography } from "antd";
import "./general.css";
import TableCode from "./component/TableCode";

const General = () => {
  return (
    <div className="flex flex-col gap-5">
      <div className="custom-box-info flex flex-col gap-3">
        <Typography className="custom-typo-title">PASSWORD & EMAIL</Typography>
        <div className="flex justify-between">
          <div className="flex gap-2">
            <Typography className="custom-typo-subtitle">Email:</Typography>
            <p className="custom-info">admin@website.com</p>
            <p className="custom-btn-general">Change</p>
          </div>
          <div className="flex gap-2">
            <Typography className="custom-typo-subtitle">Password:</Typography>
            <p className="custom-info font-bold">********</p>
            <p className="custom-btn-general">Change</p>
          </div>
        </div>
      </div>

      <div className="custom-box-info flex flex-col gap-3">
        <Typography className="custom-typo-title">WALLET ADDRESS</Typography>
        <div className="flex justify-between">
          <div className="flex gap-2">
            <Typography className="custom-typo-subtitle">
              Wallet Address:{" "}
            </Typography>
            <p className="custom-info">TMBeViiBxxSGmPiMgbvWBwF4ngSbmr8888</p>
          </div>
          <p className="custom-btn-general">Change</p>
        </div>
      </div>

      <div className="custom-box-info flex flex-col">
        <div className="flex justify-between mt-2">
        <Typography className="custom-typo-title">SIGN UP CODE</Typography>
        <p className="custom-btn-sign-up-code">New Code</p>
        </div>
       <TableCode />
      </div>
    </div>
  );
};

export default General;
