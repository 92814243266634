import dayjs from "dayjs";

export const sortOptionsDashboard = [
  {
    value: "User ID",
    label: "User ID",
  },
  {
    value: "Name",
    label: "Name",
  },
  {
    value: "Sign Up Code",
    label: "Sign Up Code",
  },
  {
    value: "Email",
    label: "Email",
  },
  {
    value: "Joined Date",
    label: "Joined Date",
  },
  {
    value: "Available Balance",
    label: "Available Balance",
  },
  {
    value: "Stake Balance",
    label: "Stake Balance",
  },
  {
    value: "Total Deposit",
    label: "Total Deposit",
  },
  {
    value: "Total Withdrawal",
    label: "Total Withdrawal",
  },
  {
    value: "Difference",
    label: "Difference",
  },
];


// User ID                  
// Name                                        
// Sign Up Code             
// Email                                                                 
// Joined Date            
// Available Balance           
// Stake Balance            
// Total Deposit            
// Total Withdrawal           
// Difference 

// {
//     "_id": "65b47a17eed9c631d38f0f7f",
//     "name": "PHAM HOANG TUNG",
//     "email": "tombroskipc@gmail.com",
//     "role": "user",
//     "password": "$2b$10$ghuX5ZY21CwxTA3ufYbFqOmRf2aNgJ5EQ2fL..yYigyV/oJEB6Z4i",
//     "availableBalance": 962150,
//     "stakedBalance": 37855,
//     "holdingBalance": 37855,
//     "totalBalance": 37855,
//     "referredType": "user",
//     "dailyTurnover": 0,
//     "selfTurnover": 0,
//     "dailyAverageStakingAmount": 0,
//     "rank": 4,
//     "totalStakedAmount": 37855,
//     "totalEarnedAmount": 0,
//     "createdAt": "2024-01-27T03:35:51.188Z",
//     "updatedAt": "2024-02-24T15:18:06.521Z",
//     "__v": 0,
//     "appWallet": "pwetk7CcQC6xst5oHfneWPn7dy8BkcTtTYmCNa9R9M",
//     "referralCode": 2008698888,
//     "confirmedEmail": true,
//     "firstStakeDate": "2024-02-24T14:43:41.605Z",
//     "id": "65b47a17eed9c631d38f0f7f"
//   }

export const userTableCol = [
  {
    title: "User ID",
    dataIndex: "id",
    key: "id",
    render: (text, record) => <a style={{
      color: "#1890ff",
    }} href={`/admin/user_info/${text}`} className="text-xs">{record.userId}</a>,
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (text) => <p className="text-xs">{text}</p>,
  },
  {
    title: "Sign Up Code",
    dataIndex: "referralCode",
    key: "referralCode",
    render: (text) => <p className="text-xs">{text}</p>,
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    render: (text) => <p className="text-xs">{text}</p>,
  },
  {
    title: "Joined Date",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (text) => <p className="text-xs">{dayjs(text).format("DD MMM YYYY")}</p>,
  },
  {
    title: "Available Balance",
    dataIndex: "availableBalance",
    key: "availableBalance",
    render: (text) => <p className="text-xs">${text}</p>,
  },
  {
    title: "Stake Balance",
    dataIndex: "stakedBalance",
    key: "stakedBalance",
    render: (text) => <p className="text-xs">${text}</p>,
  },
  {
    title: "Total Deposit",
    dataIndex: "totalDeposit",
    key: "totalDeposit",
    render: (text) => <p className="text-xs">${text}</p>,
  },
  {
    title: "Total Withdrawal",
    dataIndex: "totalWithdrawal",
    key: "totalWithdrawal",
    render: (text) => <p className="text-xs">${text}</p>,
  },
  {
    title: "Difference",
    dataIndex: "difference",
    key: "difference",
    render: (text) => <p className="text-xs">${text}</p>,
  },
]

export const transactionHistoryData = [
  {
    date: "2021-01-02",
    deposit: 1000,
    withdrawal: null,
  },
  {
    date: "2021-01-15",
    deposit: null,
    withdrawal: 1000,
  },
  {
    date: "2021-12-02",
    deposit: 1000,
    withdrawal: null,
  },
  {
    date: "2021-02-02",
    deposit: 1000,
    withdrawal: null,
  },
  {
    date: "2021-12-01",
    deposit: null,
    withdrawal: 1000,
  },
  {
    date: "2021-07-02",
    deposit: 1000,
    withdrawal: null,
  },
  {
    date: "2021-01-02",
    deposit: 1000,
    withdrawal: null,
  },
  {
    date: "2021-01-15",
    deposit: null,
    withdrawal: 1000,
  },
  {
    date: "2021-12-02",
    deposit: 1000,
    withdrawal: null,
  },
  {
    date: "2021-02-02",
    deposit: 1000,
    withdrawal: null,
  },
  {
    date: "2021-12-01",
    deposit: null,
    withdrawal: 1000,
  },
  {
    date: "2021-07-02",
    deposit: 1000,
    withdrawal: null,
  },
  {
    date: "2021-02-02",
    deposit: 1000,
    withdrawal: null,
  },
  {
    date: "2021-12-01",
    deposit: null,
    withdrawal: 1000,
  },
  {
    date: "2021-07-02",
    deposit: 1000,
    withdrawal: null,
  },
];
