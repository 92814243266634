import React from "react";
import { Controller } from "react-hook-form";
import SelectController from "../../../../../components/Select/SelectController";
import { timeZone } from "./stakingSettingData";

const TimeZoneLayout = ({ control, data }) => {
  const hours = [];
  for (let i = 0; i < 24; i++) {
    hours.push({
      label: i,
      value: i,
    });
  }

  const minutes = [];
  let a = 0;
  while (a < 55) {
    a = a + 5;
    minutes.push({
      label: a,
      value: a,
    });
  }
  return (
    <div>
      <p style={{ fontSize: "15px", fontWeight: 600 }}>Time Zone</p>
      <div className="flex gap-3 my-2">
        <Controller
          name="timeZone"
          render={({ field }) => (
            <SelectController
              field={field}
              sortOptions={timeZone}
              size="small"
              style={{ width: "105px", height: "25px" }}
            />
          )}
          control={control}
          defaultValue={data["timeZone"]}
        />{" "}
        <Controller
          name="hours"
          render={({ field }) => (
            <SelectController
              field={field}
              sortOptions={hours}
              size="small"
              style={{ width: "79px", height: "25px" }}
            />
          )}
          control={control}
          defaultValue={data["hours"]}
        />
        <p style={{ fontSize: "13px" }}>Hours</p>
        <Controller
          name="minutes"
          render={({ field }) => (
            <SelectController
              field={field}
              sortOptions={minutes}
              size="small"
              style={{ width: "79px", height: "25px" }}
            />
          )}
          control={control}
          defaultValue={data["minutes"]}
        />
        <p style={{ fontSize: "13px" }}>Minutes</p>
      </div>
    </div>
  );
};

export default TimeZoneLayout;
