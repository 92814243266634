import React from 'react';
import { Table, Button, Pagination } from 'antd';


const InfoTable = ({ dataSource, columns, currentPage, setCurrentPage, limitItems, handlePageChange, totalItems, isLoading }) => {

    return (<div>
        <div className='flex justify-center'>
            <Table
                style={{
                    borderCollapse: "separate",
                    tableLayout: "fixed",
                    width: "100%",
                }}
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                size="small"
                bordered={false}
                className="wallet-table "
                loading={isLoading}
            />
        </div>
        <br />
        <div className="flex justify-center">
            <Button type="text" onClick={() => setCurrentPage(1)}>
                First
            </Button>
            <Pagination
                current={currentPage}
                pageSize={limitItems}
                total={totalItems}
                onChange={handlePageChange}
            />
            <Button
                type="text"
                onClick={() =>
                    setCurrentPage(
                        Math.ceil(dataSource.length / limitItems)
                    )
                }
            >
                Last
            </Button>
        </div>
    </div>);
};

export default InfoTable;
