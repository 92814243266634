import React from "react";
import { stakerBalance } from "./walletData";
import Widget from "../../../components/Widget";
import './walletBalance.css'

const StakerBalance = () => {
  return (
    <Widget
      className="my-10 border-none rounded p-2"
      style={{
        backgroundColor: "#edededed",
        fontSize: "12px",
        color: "#252525",
      }}
    >
      <p className="font-bold">STAKER BALANCE</p>
      <div className="flex gap-32 custom-staker-balance">
        {stakerBalance.map((data, index) => (
          <div key={index}>
            <p style={{ fontSize: "20px", fontWeight: 500 }}>
              {data.data}{" "}
              <span style={{ fontSize: "12px", fontWeight: "normal" }}>
                USDT
              </span>
            </p>
            <p
              style={{
                fontSize: "10px",
                color: "#605e5e",
                fontWeight: "normal",
              }}
            >
              {data.desc}
            </p>
          </div>
        ))}
      </div>
    </Widget>
  );
};

export default StakerBalance;
