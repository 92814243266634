import { message } from "antd";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import AdminUserInfoApi from "../../api/admin/user-info";
import InfoTable from "../../components/InfoTable";
import AdminNavbar from "../../components/Navbar/AdminNavbar";
import "./components/adminDashboard.css";
import { sortOptionsDashboard, userTableCol } from "./components/user_table";
import { SelectMenu } from "../../components/Select";

const LIMIT_ITEMS = 10;

const AdminDashboard = ({ title }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSortOption, setCurrentSortOption] = useState("Sort By...");

  const {
    data: userData,
    isLoading: userDataLoading,
    refetch: refetchGetAllUsers,
  } = useQuery(
    ["adminGetAllUserInfoPage", currentPage],
    async () => {
      const res = await AdminUserInfoApi.adminGetAllUserInfoPage(currentPage);
      return res.data;
    },
    {
      onError: (error) => {
        message.error("Error while Getting User Info");
      },
      initialData: [],
      keepPreviousData: true,
    }
  );

  const { data: userCount, isLoading: userCountLoading } = useQuery(
    "adminGetAllUserInfoCount",
    async () => {
      const res = await AdminUserInfoApi.adminGetAllUserInfoCount();
      return res.data;
    },
    {
      onError: (error) => {
        message.error("Error while Getting User Count");
      },
      initialData: 0,
    }
  );

  useEffect(() => {
    document.title = title;
  }, [title]);

  function handlePageChange(page) {
    setCurrentPage(page);
  }

  const handleChangeOption = (value) => {
    setCurrentSortOption(value);
  };

  return (
    <div className="h-full xl:overflow-hidden sm:overflow-auto">
      <AdminNavbar />
      <div className="flex flex-col gap-4 justify-center mt-5 mx-28">
        <SelectMenu
          value={currentSortOption}
          handleChange={handleChangeOption}
          options={sortOptionsDashboard}
          width="w-44"
          style={{ border: "1px solid black", borderRadius: "5px" }}
          size={"small"}
        />
        <InfoTable
          dataSource={userData}
          columns={userTableCol}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          limitItems={LIMIT_ITEMS}
          handlePageChange={handlePageChange}
          totalItems={userCount}
          isLoading={userDataLoading}
        />
      </div>
    </div>
  );
};

export default AdminDashboard;
