import { Col, Row, message } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import AdminUserInfoApi from "../../api/admin/user-info";
import AdminNavbar from "../../components/Navbar/AdminNavbar";
import LayoutLine1 from "./components/LayoutLine1";
import LayoutLine2 from "./components/LayoutLine2";
import LayoutLine3 from "./components/LayoutLine3";
import "./components/userActivities.css";

const UserActivities = ({ title }) => {
  const { id } = useParams();
  const [userId, setUserId] = useState();
  const [userDetail, setUserDetail] = useState({});
  const [transactionHistory, setTransactionHistory] = useState([]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  const handleChangeUserID = (e) => {
    setUserId(e.target.value);
  };

  const fetchData = async (userId) => {
    try {
      const res = await AdminUserInfoApi.getUserInfo(userId);
      const transactionHistoryRes =
        await AdminUserInfoApi.getTransactionHistory(userId);
      if (res.status === 200) {
        setUserDetail(res.data.user);
        setUserId(res.data.user.userId);
      }
      if (transactionHistoryRes.status === 200) {
        setTransactionHistory(transactionHistoryRes.data);
      }
    } catch (error) {
      if (error.response.status === 500) {
        message.error("Internal Server error");
      } else {
        message.error("Error while getting user detail");
      }
    }
  };

  const fetchDataByUserId = async (userId) => {
    try {
      const res = await AdminUserInfoApi.getUserInfoByUserId(userId);
      if (res.status === 200) {
        setUserDetail(res.data.user);
      }
      const transactionHistoryRes =
        await AdminUserInfoApi.getTransactionHistory(res.data.user.id);

      if (transactionHistoryRes.status === 200) {
        setTransactionHistory(transactionHistoryRes.data);
      }
    } catch (error) {
      if (error.response.status === 500) {
        message.error("Internal Server error");
      } else {
        message.error("Error while getting user detail");
      }
    }
  };
  useEffect(() => {
    fetchData(id);
  }, [id]);

  const handleSearch = async () => {
    fetchDataByUserId(userId);
  };

  return (
    <div className="h-full xl:overflow-hidden sm:overflow-auto">
      <AdminNavbar />
      <Row gutter={16} justify="center">
        <Col xs={1} sm={1} md={1} lg={1} xl={3} />
        <Col xs={22} sm={22} md={22} lg={22} xl={18}>
          <LayoutLine1
            id={id}
            userId={userDetail?.userId}
            handleChangeUserID={handleChangeUserID}
            handleSearch={handleSearch}
          />
          <div className="flex justify-between">
            <LayoutLine2 userDetail={userDetail} />
            <LayoutLine3 transactionHistory={transactionHistory} />
          </div>
        </Col>
        <Col xs={1} sm={1} md={1} lg={1} xl={3} />
      </Row>
    </div>
  );
};

export default UserActivities;
