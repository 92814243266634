import React, { useState } from "react";
import { useQuery } from "react-query";
import Widget from "../../../components/Widget";
import UserApi from "../../../api/user";
import Deposit from "../../../components/Deposit";
import Withdraw from "../../../components/Withdraw";
import './walletBalance.css'
import { message } from "antd";

const WalletBalance = () => {
  const [isDepositModalOpen, setIsDepositModalOpen] = useState(false);
  const [isWithdrawModelOpen, setIsWithdrawModelOpen] = useState(false);
  const [balance, setBalance] = useState({});

  const { isLoading: availableWalletLoading, refetch: refetchWalletBalance } =
    useQuery("adminWalletBalance", UserApi.getSelf, {
      onSuccess: (res) => {
        setBalance(res.data);
      },
      onError: (error) => {
        message.error("Error while Getting profile wallet balance");
      },
    });

  return (
    <Widget className="mb-10 mt-20 border border-slate-400 rounded-none">
      <div
        className="flex justify-between"
        style={{ color: "#252525", fontSize: "12px" }}
      >
        <div className="flex flex-col gap-2 font-bold p-2">
          <p>WALLET BALANCE</p>
          <p style={{ fontSize: "20px" }}>
            {balance?.availableBalance}
            <span className="font-normal" style={{ fontSize: "12px", paddingLeft: '3px' }}>
              USDT
            </span>
          </p>
        </div>
        <div className="flex gap-5 my-auto mr-2">
          <button
            className="rounded-none custom-button-wallet-setting"
            onClick={() => setIsDepositModalOpen(true)}
          >
            Deposit
          </button>
          <button
            className="rounded-none custom-button-wallet-setting"
            onClick={() => setIsWithdrawModelOpen(true)}
          >
            Withdrawal
          </button>
        </div>
      </div>

      <Deposit
        isModalOpen={isDepositModalOpen}
        setIsModalOpen={setIsDepositModalOpen}
        refetch={refetchWalletBalance}
      />
      <Withdraw
        isModalOpen={isWithdrawModelOpen}
        setIsModalOpen={setIsWithdrawModelOpen}
        refetch={refetchWalletBalance}
      />
    </Widget>
  );
};

export default WalletBalance;
