import axios from 'axios';
import { getBearerToken } from '../utils/localStorage';
axios.defaults.baseURL = process.env.REACT_APP_PUBLIC_API_URL;

export default class UserApi {
  static async getSelf () {
    const token = getBearerToken();
    const res = await axios.get('/user/me', {
      headers: {
        authorization: token,
      },
    });
    return res;
  }

  static async getUser (userId) {
    const res = await axios.get(`/user/${userId}`);
    return res;
  }

  static async getSelfAccountBalance() {
    const token = getBearerToken();
    const res = await axios.get('/user/me/balance', {
      headers: {
        authorization: token,
      },
    });
    return res;
  }

}
