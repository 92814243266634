import dayjs from "dayjs";

// Stake Period
// Total Stake Amount
// Daily Return Rate
// Daily Income
// Period Income
// Stake Date
// Stake Unlock Date
// {
//   "_id": "65da009e3284a8cc75260f68",
//   "stakeDate": "2024-02-24T14:43:41.605Z",
//   "lockupDuration": "30 Days",
//   "stakeUnlockDate": "2024-03-25T14:43:41.605Z",
//   "stakeAmount": 500,
//   "annualPercentageRate": 5,
//   "dailyReturn": 0.01,
//   "stakeUnlockDateOption": "restake",
//   "userId": "65b47a17eed9c631d38f0f7f",
//   "createdAt": "2024-02-24T14:43:42.541Z",
//   "updatedAt": "2024-02-24T14:43:42.541Z",
//   "__v": 0,
//   "id": "65da009e3284a8cc75260f68"
// }
export const myStakeCols = [
  {
    title: <p className="font-normal">Stake Period</p>,
    dataIndex: "lockupDuration",
    key: "lockupDuration",
    align: "center",
    // render: (text) => <p className="text-xs font-semibold">{dayjs(text).format("DD MMM YYYY")}</p>,
    render: (text) => <p className="text-xs font-semibold">{text}</p>,
  },
  {
    title: <p className="font-normal">Total Stake Amount</p>,
    dataIndex: "stakeAmount",
    key: "stakeAmount",
    align: "center",
    render: (text) => <p className="text-xs font-semibold">{text}</p>,
  },
  {
    title: <p className="font-normal">Daily Return Rate</p>,
    dataIndex: "annualPercentageRate",
    key: "annualPercentageRate",
    align: "center",
    render: (text) => <p className="text-xs font-semibold">{(Number(text) / 365).toFixed(3)}</p>,
  },
  {
    title: <p className="font-normal">Daily Income</p>,
    dataIndex: "dailyReturn",
    key: "dailyReturn",
    align: "center",
    render: (text) => <p className="text-xs font-semibold">{text}</p>,
  },
  {
    title: <p className="font-normal">Period Income</p>,
    dataIndex: "dailyReturn",
    key: "dailyReturn",
    align: "center",
    render: (text) => <p className="text-xs font-semibold">{text}</p>,
  },
  {
    title: <p className="font-normal">Stake Date</p>,
    dataIndex: "stakeDate",
    key: "stakeDate",
    align: "center",
    render: (text) => <p className="text-xs font-semibold">{dayjs(text).format("DD MMM YYYY")}</p>,
  },
  {
    title: <p className="font-normal">Stake Unlock Date</p>,
    dataIndex: "stakeUnlockDate",
    key: "stakeUnlockDate",
    align: "center",
    render: (text) => <p className="text-xs font-semibold">{dayjs(text).format("DD MMM YYYY")}</p>,
  },
]

export const transactionHistoryData = [
  {
    date: "2021-01-02",
    deposit: 1000,
    withdrawal: null,
  },
  {
    date: "2021-01-15",
    deposit: null,
    withdrawal: 1000,
  },
  {
    date: "2021-12-02",
    deposit: 1000,
    withdrawal: null,
  },
  {
    date: "2021-02-02",
    deposit: 1000,
    withdrawal: null,
  },
  {
    date: "2021-12-01",
    deposit: null,
    withdrawal: 1000,
  },
  {
    date: "2021-07-02",
    deposit: 1000,
    withdrawal: null,
  },
  {
    date: "2021-01-02",
    deposit: 1000,
    withdrawal: null,
  },
  {
    date: "2021-01-15",
    deposit: null,
    withdrawal: 1000,
  },
  {
    date: "2021-12-02",
    deposit: 1000,
    withdrawal: null,
  },
  {
    date: "2021-02-02",
    deposit: 1000,
    withdrawal: null,
  },
  {
    date: "2021-12-01",
    deposit: null,
    withdrawal: 1000,
  },
  {
    date: "2021-07-02",
    deposit: 1000,
    withdrawal: null,
  },
  {
    date: "2021-02-02",
    deposit: 1000,
    withdrawal: null,
  },
  {
    date: "2021-12-01",
    deposit: null,
    withdrawal: 1000,
  },
  {
    date: "2021-07-02",
    deposit: 1000,
    withdrawal: null,
  },
];
