export const convertFormDataToArray = (formData) => {
  const data = [];
  for (const [key, value] of Object.entries(formData)) {
    // regex match for key with dataLine1, dataLine2, dataLine3
    if (key.match(/Line[1-9]/)) {
      const [dataLine, lineIndex] = key.split("Line");
      const index = parseInt(lineIndex) - 1;
      if (data[index]) {
        data[index] = { ...data[index], [dataLine]: value };
      } else {
        data[index] = { [dataLine]: value };
      }
    }
  }

  return data;
};
