import React from 'react';

const ProfileIcon = ({ name }) => {
  const initial = name ? name[0].toUpperCase() : ''; // Extract the first letter and make it uppercase

  return (
    <div style={styles.iconContainer}>
      {initial}
    </div>
  );
};

// Styling for the icon container
const styles = {
  iconContainer: {
    width: '60px', 
    height: '60px',
    borderRadius: '50%', // Makes the container circular
    backgroundColor: 'black', 
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white', 
    fontSize: '38px',
  }
};

export default ProfileIcon;
