import dayjs from "dayjs";

export const userActivitiesCols = [
  {
    title: "Date & Time",
    dataIndex: "date",
    key: "date",
    align: "center",
    render: (text) => (
      <p style={{ color: "#252525", fontSize: "11px" }}>
        {dayjs(text).format("DD/MM/YY")}
      </p>
    ),
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    align: "center",
    render: (text) => (
      <p style={{ color: "#252525", fontSize: "11px" }}>{text}</p>
    ),
  },
  {
    title: "Amount (USDT)",
    dataIndex: "amount",
    key: "amount",
    align: "center",
    render: (text) => (
      <p style={{ color: "#252525", fontSize: "11px" }}>{text}</p>
    ),
  },
  {
    title: "Source",
    dataIndex: "id",
    key: "id",
    align: "center",
    render: (text) => (
      <p style={{ color: "#252525", fontSize: "11px" }}>{text}</p>
    ),
  },
];

export const userActivitiesData = [
  {
    key: 1,
    dateTime: "2021-08-23 12:00:00",
    type: "Stake",
    amount: "16,000",
    source: "S2701220168",
  },
  {
    key: 2,
    dateTime: "2021-08-23 12:00:00",
    type: "Deposit",
    amount: "18,000",
    source: "Self",
  },
  {
    key: 3,
    dateTime: "2021-08-01 12:00:00",
    type: "Stake",
    amount: "1,000",
    source: "Self",
  },
  {
    key: 4,
    dateTime: "2021-08-01 12:00:00",
    type: "Stake",
    amount: "1,000",
    source: "Self",
  },
  {
    key: 5,
    dateTime: "2021-08-12 12:00:00",
    type: "Stake",
    amount: "16,000",
    source: "S2701220168",
  },
  {
    key: 6,
    dateTime: "2021-08-01 12:00:00",
    type: "Deposit",
    amount: "18,000",
    source: "Self",
  },
  {
    key: 7,
    dateTime: "2021-08-01 12:00:00",
    type: "Stake",
    amount: "1,000",
    source: "Self",
  },
  {
    key: 8,
    dateTime: "2021-08-01 12:00:00",
    type: "Stake",
    amount: "1,000",
    source: "Self",
  },
  {
    key: 9,
    dateTime: "2021-07-01 12:00:00",
    type: "Stake",
    amount: "16,000",
    source: "S2701220168",
  },
  {
    key: 10,
    dateTime: "2021-08-01 12:00:00",
    type: "Deposit",
    amount: "18,000",
    source: "Self",
  },
  {
    key: 11,
    dateTime: "2021-06-01 12:00:00",
    type: "Stake",
    amount: "1,000",
    source: "Self",
  },
  {
    key: 12,
    dateTime: "2021-08-01 12:00:00",
    type: "Stake",
    amount: "1,000",
    source: "Self",
  },
  {
    key: 13,
    dateTime: "2021-08-01 12:00:00",
    type: "Stake",
    amount: "16,000",
    source: "S2701220168",
  },
  {
    key: 14,
    dateTime: "2021-08-01 12:00:00",
    type: "Deposit",
    amount: "18,000",
    source: "Self",
  },
  {
    key: 15,
    dateTime: "2021-08-01 12:00:00",
    type: "Stake",
    amount: "1,000",
    source: "Self",
  },
  {
    key: 16,
    dateTime: "2021-08-01 12:00:00",
    type: "Stake",
    amount: "1,000",
    source: "Self",
  },
  {
    key: 17,
    dateTime: "2021-08-01 12:00:00",
    type: "Stake",
    amount: "16,000",
    source: "S2701220168",
  },
  {
    key: 18,
    dateTime: "2021-08-01 12:00:00",
    type: "Deposit",
    amount: "18,000",
    source: "Self",
  },
  {
    key: 19,
    dateTime: "2021-08-01 12:00:00",
    type: "Stake",
    amount: "1,000",
    source: "Self",
  },
  {
    key: 20,
    dateTime: "2021-08-01 12:00:00",
    type: "Stake",
    amount: "1,000",
    source: "Self",
  },
  {
    key: 21,
    dateTime: "2021-08-01 12:00:00",
    type: "Stake",
    amount: "16,000",
    source: "S2701220168",
  },
  {
    key: 22,
    dateTime: "2021-08-01 12:00:00",
    type: "Deposit",
    amount: "18,000",
    source: "Self",
  },
  {
    key: 23,
    dateTime: "2021-08-01 12:00:00",
    type: "Stake",
    amount: "1,000",
    source: "Self",
  },
  {
    key: 24,
    dateTime: "2021-08-01 12:00:00",
    type: "Stake",
    amount: "1,000",
    source: "Self",
  },
];

export const userActivitiesCols2 = [
  {
    title: "Date & Time",
    dataIndex: "dateTime",
    key: "dateTime",
    render: (text) => (
      <p style={{ color: "#252525", fontSize: "11px" }}>
        {dayjs(text).format("DD/MM/YY")}
      </p>
    ),
  },
  {
    title: "Activities",
    dataIndex: "activities",
    key: "activities",
    render: (text) => (
      <p style={{ color: "#252525", fontSize: "11px" }}>{text}</p>
    ),
  },
  {
    title: "Ip",
    dataIndex: "ip",
    key: "ip",
    render: (text) => (
      <p style={{ color: "#252525", fontSize: "11px" }}>{text}</p>
    ),
  },
  {
    title: "Device ID",
    dataIndex: "deviceId",
    key: "deviceId",
    render: (text) => (
      <p style={{ color: "#252525", fontSize: "11px" }}>{text}</p>
    ),
  },
];

export const userActivitiesData2 = [
  {
    key: 1,
    dateTime: "2021-08-16 12:00:00",
    activities: "Sign up",
    ip: "13.146.229.162",
    deviceIp: "358780104180711",
  },
  {
    key: 2,
    dateTime: "2021-08-08 12:00:00",
    activities: "Sign up",
    ip: "13.146.229.162",
    deviceIp: "358780104180711",
  },
  {
    key: 3,
    dateTime: "2021-08-01 12:00:00",
    activities: "Sign up",
    ip: "13.146.229.162",
    deviceIp: "358780104180711",
  },
  {
    key: 4,
    dateTime: "2021-08-20 12:00:00",
    activities: "Sign up",
    ip: "13.146.229.162",
    deviceIp: "358780104180711",
  },
];
