import React, { useState } from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Select } from "antd";
import './select.css'

const SelectController = ({ field, sortOptions, size, style=null }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleDropdownVisibleChange = (open) => {
    setIsOpen(open);
  };
  return (
    <Select
      {...field}
      size={size}
      options={sortOptions}
      className="w-32"
      style={style}
      dropdownRender={(menu) => (
        <div
          onClick={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.preventDefault()}
        >
          {menu}
        </div>
      )}
      onDropdownVisibleChange={handleDropdownVisibleChange}
      suffixIcon={isOpen ? <UpOutlined /> : <DownOutlined />}
    />
  );
};

export default SelectController;
