import React, { useEffect } from 'react';
import { useMutation, useQuery } from "react-query";
import AuthApi from '../../api/auth';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { message } from 'antd';

const ResetPassword = () => {
    const navigator = useNavigate();
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const otp = searchParams.get("otp");
    const {
        data,
        isLoading,
        isError,
        isSuccess,
    } = useQuery("checkToken", async () => {
        return await AuthApi.checkToken("FORGOT_PASSWORD", token);
    }, {
        enabled: !!token,
        onSuccess: (res) => {
            console.log(res);
        },
        onError: (error) => {
            console.log(error);
        }
    });

    useEffect(() => {
        if (isSuccess) {
            message.info("Valid token, Redirecting to reset password page");
            setTimeout(() => {
                // Redirect to reset password page with token and otp
                const urlSearchParams = new URLSearchParams();
                urlSearchParams.append("token", token);
                urlSearchParams.append("otp", otp);
                urlSearchParams.append("action", "RESET_PASSWORD");
                navigator(`/?${urlSearchParams.toString()}`);
            }, 2000);
        }
    }
        , [isSuccess]);

    return (
        <div>
            {
                isLoading ? "Checking token..." :
                    isError ? "Link is invalid" :
                        isSuccess ? "Success" : "Checking token..."
            }
        </div>
    );
};

export default ResetPassword;
