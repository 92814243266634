import { Select } from "antd";
import React, { useState } from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

const SelectMenu = ({ value, handleChange, options, size, width, defaultValue,style }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleDropdownVisibleChange = (open) => {
    setIsOpen(open);
  };
  return (
    <Select
      defaultValue={defaultValue}
      value={value}
      className={width}
      onChange={handleChange}
      options={options}
      size={size}
      onDropdownVisibleChange={handleDropdownVisibleChange}
      suffixIcon={isOpen ? <UpOutlined style={{color:'black'}} /> : <DownOutlined style={{color:'black'}}/>}
      style={style}
    />
  );
};

export default SelectMenu;
