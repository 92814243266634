import { Space, Table } from "antd";
import React from "react";
import "../../General/general.css";
import '../stakeSetting.css';

const stakeSettingData = [
  {
    id: 1,
    stakePeriod: '1 Day',
    dailyReturnRate: '0.25%',
    minStakeAmount: '10 USDT',
  },
  {
    id: 2,
    stakePeriod: '7 Days',
    dailyReturnRate: '0.25%',
    minStakeAmount: '10 USDT',
  },
];

const TableStakeSetting = () =>{

  //columns
  const stakeSettingCols = [
    {
      title: <p className="font-bold text-xs">Stake Period</p>,
      dataIndex: "stakePeriod",
      key: "stakePeriod",
      align: "center",
      render: (text) => <p className="font-bold custom-font-table-data">{text}</p>,
    },
    {
      title: <p className="font-bold text-xs">Daily Return Rate</p>,
      dataIndex: "dailyReturnRate",
      key: "dailyReturnRate",
      align: "center",
      render: (text) => <p className="font-bold custom-font-table-data">{text}</p>,
    },
    {
      title: <p className="font-bold text-xs">Min.Stake Amount </p>,
      dataIndex: "minStakeAmount",
      key: "minStakeAmount",
      align: "center",
      render: (text) => <p className="font-bold custom-font-table-data">{text}</p>,
    },
    {
      title: <p className="text-transparent">Action</p>,
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <p className="custom-btn-sign-up-code cursor-pointer">Change</p>
        </Space>
      ),
    },
  ];

 
  return (
    <div>
      <Table
        style={{
          borderCollapse: "separate",
          tableLayout: "fixed",
          width: "100%",
          marginTop: 10,
        }}
        columns={stakeSettingCols}
        dataSource={stakeSettingData}
        pagination={false}
        size="small"
        bordered={false}
        className="stake-setting-table"
      />
    </div>
  );
};

export default TableStakeSetting;
