import { Col, Row } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import AdminNavbar from "../../components/Navbar/AdminNavbar";
import FrontendCMS from "./components/FrontendCMS";
import Functions from "./components/Functions";
import SidebarMenu from "./components/SidebarMenu";
import RankAndLimits from "./components/RankAndLimits";
import RewardsAndBonus from "./components/RewardsAndBonus";
import Staking from "./components/Staking";
import AccountAndSecurity from "./components/AccountAndSecurity";
import General from "./components/General";
import PendingWithdrawal from "./components/PendingWithdrawal";
import StakingAndWithdrawal from "./components/StakingAndWithdrawal";

const Setting = ({ title }) => {
  const [activeSideBar, setActiveSideBar] = useState("general");

  const sideBarItems = useMemo(() => {
    return [
      // {
      //   name: "Account & Security",
      //   key: "accountAndSecurity",
      //   render: <AccountAndSecurity />,
      // },
      // {
      //   name: "Frontend CMS",
      //   key: "frontendCMS",
      //   render: <FrontendCMS />,
      // },
      // {
      //   name: "Functions",
      //   key: "functions",
      //   render: <Functions />,
      // },
      // {
      //   name: "Rank & Limits",
      //   key: "rankAndLimits",
      //   render: <RankAndLimits />,
      // },
      // {
      //   name: "Rewards & Bonus",
      //   key: "rewardAndBonus",
      //   render: <RewardsAndBonus />,
      // },
      {
        name: "Staking",
        key: "staking",
        render: <Staking />,
      },
      // {
      //   name: "Tutorial",
      //   key: "tutorial",
      //   render: "",
      // },
      { name: "General", key: "general", render: <General /> },
      { name: "Pending Withdrawal", key: "pendingWithdrawal", render: <PendingWithdrawal /> },
      { name: "Staking & Withdrawal", key: "stakingWithdrawal", render: <StakingAndWithdrawal /> },
    ];
  }, []);

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <div>
      <AdminNavbar />
      {/* <p
            style={{ color: "#252525", fontSize: "20px", fontWeight: 600 }}
            className="mt-4 ml-2 tracking-wide"
          >
            Settings
          </p> */}
      <Row justify="center">
        <Col xs={23} sm={23} md={23} lg={15} xl={15} xxl={10}>
          <div className="flex flex-col">
            <SidebarMenu
              items={sideBarItems}
              activeKey={activeSideBar}
              setActiveKey={setActiveSideBar}
            />
            {sideBarItems.find((item) => item.key === activeSideBar)?.render}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Setting;
