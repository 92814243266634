import dayjs from "dayjs";

export const stakerBalance = [
  { data: "673", desc: "Total Available Balance" },
  { data: "338", desc: "Total Stake Balance" },
  { data: "1,011", desc: "Total Balance" },
];

export const cashFlow = [
  { data: "489", desc: "Total Deposit" },
  { data: "103", desc: "Total Withdrawal" },
  { data: "386", desc: "Cashflow" },
];

export const sortCashflowOptions = [
  { value: "1 Day", label: "1 Day" },
  {
    value: "1 Week",
    label: "1 Week",
  },
  { value: "1 Month", label: "1 Month" },
  {
    value: "2 Months",
    label: "2 Months",
  },
  {
    value: "3 Months",
    label: "3 Months",
  },
  { value: "6 Months", label: "6 Months" },
  { value: "7 Year", label: "1 Year" },
  { value: "MTD", label: "MTD" },
  {
    value: "YTD",
    label: "YTD",
  },
];

export const transactionHistoryCols = [
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    render: (text) => <p className="text-xs">{dayjs(text).format("DD MMM YYYY")}</p>,
  },
  {
    title: "Deposit (USDT)",
    dataIndex: "deposit",
    key: "depo",
    render: (text) => <p className="text-xs">{text}</p>,
  },
  {
    title: "Withdrawal (USDT)",
    dataIndex: "withdrawal",
    key: "withdrawal",
    render: (text) => <p className="text-xs">{text}</p>,
  },
]

export const transactionHistoryData = [
  {
    date: "2021-01-02",
    deposit: 1000,
    withdrawal: null,
  },
  {
    date: "2021-01-15",
    deposit: null,
    withdrawal: 1000,
  },
  {
    date: "2021-12-02",
    deposit: 1000,
    withdrawal: null,
  },
  {
    date: "2021-02-02",
    deposit: 1000,
    withdrawal: null,
  },
  {
    date: "2021-12-01",
    deposit: null,
    withdrawal: 1000,
  },
  {
    date: "2021-07-02",
    deposit: 1000,
    withdrawal: null,
  },
  {
    date: "2021-01-02",
    deposit: 1000,
    withdrawal: null,
  },
  {
    date: "2021-01-15",
    deposit: null,
    withdrawal: 1000,
  },
  {
    date: "2021-12-02",
    deposit: 1000,
    withdrawal: null,
  },
  {
    date: "2021-02-02",
    deposit: 1000,
    withdrawal: null,
  },
  {
    date: "2021-12-01",
    deposit: null,
    withdrawal: 1000,
  },
  {
    date: "2021-07-02",
    deposit: 1000,
    withdrawal: null,
  },
  {
    date: "2021-02-02",
    deposit: 1000,
    withdrawal: null,
  },
  {
    date: "2021-12-01",
    deposit: null,
    withdrawal: 1000,
  },
  {
    date: "2021-07-02",
    deposit: 1000,
    withdrawal: null,
  },
];
