import axios from "axios";
import { getBearerToken } from "../utils/localStorage";
axios.defaults.baseURL =
  process.env.REACT_APP_PUBLIC_API_URL;

export default class WalletApi {
  static async withdraw(amount, userWallet) {
    const token = getBearerToken();
    const res = await axios.post(
      "/wallet/withdraw",
      {
        amount,
        userWallet,
      },
      {
        headers: {
          authorization: token,
        },
      }
    );
    return res;
  }

  static async deposit(amount) {
    const token = getBearerToken();
    const res = await axios.post(
      "/wallet/deposit",
      {
        amount,
      },
      {
        headers: {
          authorization: token,
        },
      }
    );
    return res;
  }

  static async getTransactionHistory(userId) {
    const token = getBearerToken();
    const res = await axios.get(`/transactionHistory/me`, {
      headers: {
        authorization: token,
      },
    });
    return res;
  }
}
