import { Col, Row, Typography } from "antd";
import "./footer.css";
import Facebook from "../../assets/fb_icon.png";
import X from "../../assets/x_icon.png";
import Linkedin from "../../assets/linkedin_icon.png";

const Footer = () => {
  return (
    <div
      style={{
        backgroundColor: "whitesmoke",
      }}
    >
      <Row>
        <Col xs={1} sm={1} md={1} lg={1} xl={4} />
        <Col xs={22} sm={22} md={22} lg={22} xl={16}>
          <div className="flex flex-row justify-between">
            <div className="custom-footer-margin-y">
              <Typography
                style={{
                  color: "rgb(37,37,37)",
                }}
                className="custom-footer-typo"
              >
                500 Terry Francois Street, San Francisco, CA 94158 <br />
                info@mysite.com | 123-456-7890
              </Typography>
              <Typography
                style={{
                  color: "rgb(160,160,159)",
                }}
                className="custom-footer-typo-2"
              >
                © 2023 by Roland VC. Proudly created with Wix.com
              </Typography>
            </div>
            <div className="flex flex-row gap-3 custom-footer-margin-y">
              <img src={Facebook} alt="facebook" className="w-1/4 h-1/3"/>
              <img src={X} alt="x" className="w-1/4 h-1/3"/>
              <img src={Linkedin} alt="linkedin" className="w-1/4 h-1/3"/>
            </div>
          </div>
        </Col>
        <Col xs={1} sm={1} md={1} lg={1} xl={4} />
      </Row>
    </div>
  );
};

export default Footer;
