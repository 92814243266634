import { Button, Form, Input, Modal, Typography, message } from "antd";
import React, { useState } from "react";
import { useMutation } from "react-query";
import AuthApi from "../../../../api/auth";
import UserInfoApi from "../../../../api/profile/user-info";
import { emailRegExp } from "../../../../utils/regFormat";

const ChangeEmail = ({ openEmail, setOpenEmail, user }) => {
  const [form] = Form.useForm();
  const [messageApi] = message.useMessage();
  const [formNewEmail] = Form.useForm();
  const [openNewEmail, setOpenNewEmail] = useState(false);
  //const [errorMessages, setErrorMessages] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);

  const { mutate: verifyPassword } = useMutation(
    async (values) => {
      const res = await AuthApi.verifyPassword(values.currentPassword);
      return res;
    },
    {
      onSuccess: (res) => {
        //setErrorMessages(false);
        setOpenNewEmail(true);
        form.resetFields("currentPassword");
      },
      onError: (error) => {
        message.error(error.response.data.message);
        //setErrorMessages(true);
      },
    }
  );

  const checkPassword = async (values) => {
    verifyPassword(values);
  };

  const handleCancelChangeEmail = () => {
    setOpenEmail(false);
    //setErrorMessages(false);
    form.resetFields();
  };

  const { mutate: putUserInfo } = useMutation(
    async (userData) => {
      return await UserInfoApi.putUserInfo(user?.id, userData);
    },
    {
      onSuccess: (res) => {
        setOpenNotification(true);
      },
      onError: (error) => {
        messageApi.open({
          type: "error",
          content: "Error while update info",
        });
      },
    }
  );

  const handleSaveMail = (values) => {
    if (values.newEmail !== values.confirmEmail) {
      formNewEmail.setFields([
        {
          name: "confirmEmail",
          errors: ["Entered emails do not match"],
        },
      ]);
    } else {
      putUserInfo({ email: values.newEmail });
      setTimeout(() => {
        localStorage.clear();
        window.location.replace("/");
      }, 2000);
    }
  };

  const handleCancelNewEmail = () => {
    setOpenNewEmail(false);
  };

  const handleCancelOpenNotification = () => {
    setOpenNotification(false);
    formNewEmail.resetFields();
    setOpenNewEmail(false);
    setOpenEmail(false);
  };

  return (
    <>
      <Modal
        open={openEmail}
        onCancel={handleCancelChangeEmail}
        footer={null}
        closeIcon={false}
        className="info-modal"
      >
        <Typography className="text-xl font-bold text-center">
          Change Email
        </Typography>
        {/* <Form
          form={form}
          layout="vertical"
          className="mt-4"
          onFinish={checkMail}
        >
          <Typography className="font-bold text-sm py-2">
            Current Email
          </Typography>
          <div className="flex flex-col justify-center">
            <Form.Item
              name="currentEmail"
              rules={[
                {
                  required: true,
                  message: "Please input your current email!",
                },
                {
                  pattern: emailRegExp,
                  message: "Please input a valid email!",
                },
              ]}
            >
              <Input
                placeholder="Enter your current email"
                className="info-input"
                type="email"
              />
            </Form.Item>
            <Form.Item className="mx-auto">
              <Button htmlType="submit">Check Email</Button>
            </Form.Item>
          </div>
        </Form> */}
        <Form
          form={form}
          layout="vertical"
          className="mt-4"
          onFinish={checkPassword}
        >
          <Typography className="font-bold text-sm py-2">
            Current Password
          </Typography>
          <div className="flex flex-col justify-center">
            <Form.Item
              name="currentPassword"
              rules={[
                {
                  required: true,
                  message: "Please input your current password!",
                },
                // {
                //   pattern: passwordRegExp,
                //   message: "Please input a valid password!",
                // },
              ]}
            >
              <Input
                placeholder="Enter your current password"
                className="info-input"
                type="password"
              />
              {/* {errorMessages && (
                <p className="text-red-500 text-xs italic mt-1">
                  Password is incorrect
                </p>
              )} */}
            </Form.Item>
            <Form.Item className="mx-auto">
              <Button htmlType="submit">Next</Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>

      <Modal
        open={openNewEmail}
        onCancel={handleCancelNewEmail}
        footer={null}
        closeIcon={false}
        className="info-modal"
      >
        <Typography className="text-xl font-bold text-center">
          Change Email
        </Typography>
        <Form
          form={formNewEmail}
          layout="vertical"
          className="mt-4"
          onFinish={handleSaveMail}
        >
          <div className="flex flex-col justify-center">
            <Typography className="font-bold text-sm py-2">
              New Email
            </Typography>
            <Form.Item
              name="newEmail"
              rules={[
                {
                  required: true,
                  message: "Please input your new email!",
                },
                {
                  pattern: emailRegExp,
                  message: "Invalid email format",
                },
              ]}
            >
              <Input
                placeholder="Enter your new email"
                className="info-input"
                type="email"
              />
            </Form.Item>
            <Typography className="font-bold text-sm py-2">
              Confirm Email
            </Typography>
            <Form.Item
              name="confirmEmail"
              rules={[
                {
                  required: true,
                  message: "Please input your confirm email!",
                },
                {
                  pattern: emailRegExp,
                  message: "Invalid email format",
                },
              ]}
            >
              <Input
                placeholder="Enter your confirm email"
                className="info-input"
                type="email"
              />
            </Form.Item>
            <Form.Item className="mx-auto">
              <Button htmlType="submit">Save</Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>

      <Modal
        open={openNotification}
        onCancel={handleCancelOpenNotification}
        footer={null}
        className="info-modal mt-10 "
        width={400}
      >
        <Typography>Your email has been updated successfully!</Typography>
      </Modal>
    </>
  );
};

export default ChangeEmail;
