import { Col, Row, message } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import UserApi from "../../api/user";
import BgProfile from "../../assets/bg_profile.png";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar";
import MyInfo from "./MyInfo";
import MyStake from "./MyStake";
import MyWallet from "./MyWallet";
import ProfileMenu from "./components/ProfileMenu";

const Container = styled.div`
  background-color: black;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  overflow-x: hidden;
`;

const Profile = ({ title }) => {
  const location = useLocation();
  const [activeSideBar, setActiveSideBar] = useState(
    location?.state?.defaultActiveSideBar || "my_info"
  );
  const [accountInfo, setAccountInfo] = useState({});

  const sideBarItems = useMemo(() => {
    return [
      {
        name: "My Account",
        key: "my_info",
        render: <MyInfo />,
      },
      {
        name: "My Wallet",
        key: "my_wallet",
        render: <MyWallet accountInfo={accountInfo} />,
      },
      // {
      //   name: "My Stake",
      //   key: "my_stake",
      //   render: <MyStake />,
      // },
    ];
  }, [accountInfo]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  const { isLoading: accountInfoLoading } = useQuery(
    "getSelf",
    UserApi.getSelf,
    {
      onSuccess: (res) => {
        setAccountInfo(res.data);
      },
      onError: (error) => {
        message.error("Error while Getting available balance");
      },
    }
  );

  return (
    <Container>
      <div className="flex-none">
        <Navbar />
      </div>
      <div className="grow">
        <Row gutter={16} className="mb-12" justify="center">
          <Col xs={23} sm={23} md={23} lg={23} xl={12}>
            <ProfileMenu
              items={sideBarItems}
              activeKey={activeSideBar}
              setActiveKey={setActiveSideBar}
            />
          </Col>

          <Col xs={23} sm={23} md={23} lg={23} xl={20}>
            {sideBarItems.find((item) => item.key === activeSideBar)?.render}
          </Col>
        </Row>
      </div>
      <div className="flex-none">
        {" "}
        <Footer />
      </div>
    </Container>
  );
};

export default Profile;
