import React from "react";
import { Typography } from "antd";
import '../General/general.css'
import TablePendingWithdrawal from "./component/TablePendingWithdrawal";

const PendingWithdrawal = () => {
  return (
    <div className="custom-box-info flex flex-col">
      <div className="flex justify-between mt-2">
        <Typography className="custom-typo-title">PENDING WITHDRAWAL</Typography>
      </div>
      <TablePendingWithdrawal />
    </div>
  );
};

export default PendingWithdrawal;
