import { Button, Pagination, Table } from "antd";
import React, { useState } from "react";
import { SelectMenu } from "../../../components/Select";
import Widget from "../../../components/Widget";
import { sortOptions } from "../../profile/components/walletComponents/walletItems";
import { transactionHistoryCols, transactionHistoryData } from "./walletData";

const TransactionHistory = () => {
  const _ = require("lodash");
  //Transaction History
  const [currentSortTransactionOption, setCurrentSortTransactionOption] =
    useState(sortOptions[0].value);
  const [currentPage, setCurrentPage] = useState(1);
  const limitItems = 12;
  const totalItems = transactionHistoryData.length;
  const sumDeposit = _.sumBy(transactionHistoryData, "deposit");
  const sumWithdrawal = _.sumBy(transactionHistoryData, "withdrawal");

  const handleChangeSortTransaction = (value) => {setCurrentSortTransactionOption(value)}

  // Table
  const startIndex = (currentPage - 1) * limitItems + 1;
  const endIndex = Math.min(currentPage * limitItems, totalItems);
  const sortedData =
    currentSortTransactionOption === "Latest First"
      ? [...transactionHistoryData].sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        )
      : [...transactionHistoryData].sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );

  const currentPageData = sortedData.slice(startIndex - 1, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  return (
    <Widget className="mt-10 mb-5 border border-slate-500 rounded p-2">
      <div className="flex justify-between">
        <p className="font-bold">TRANSACTION HISTORY</p>
        <div className="flex gap-2">
          <p className="pt-1">Sort by:</p>
          <SelectMenu
            defaultValue={sortOptions[0].value}
            handleChange={handleChangeSortTransaction}
            options={sortOptions}
            width="w-32"
          />
        </div>
      </div>
      <Table
        style={{
          borderCollapse: "separate",
          tableLayout: "fixed",
          width: "100%",
          marginTop: 10,
          height: "450px",
        }}
        columns={transactionHistoryCols}
        dataSource={currentPageData}
        pagination={false}
        size="small"
        bordered={false}
        className="wallet-table "
      />

      <div className="flex justify-center mt-3">
        <Button type="text" onClick={() => setCurrentPage(1)}>
          First
        </Button>
        <Pagination
          current={currentPage}
          pageSize={limitItems}
          total={transactionHistoryData.length}
          onChange={handlePageChange}
        />
        <Button
          type="text"
          onClick={() =>
            setCurrentPage(
              Math.ceil(transactionHistoryData.length / limitItems)
            )
          }
        >
          Last
        </Button>
      </div>
      <div className="mt-3 text-xs">
        Showing {startIndex} - {endIndex} of {totalItems} transactions
      </div>
      <div
        style={{ backgroundColor: "#ededed", height: "72px" }}
        className="mt-4 flex justify-evenly items-center"
      >
        <div>
          <p style={{ fontSize: "20px", fontWeight: 500 }}>
            {sumDeposit}{" "}
            <span style={{ fontSize: "12px", fontWeight: "normal" }}>USDT</span>
          </p>
          <p
            style={{
              fontSize: "10px",
              color: "#605e5e",
              fontWeight: "normal",
            }}
          >
            Total Deposit
          </p>
        </div>
        <div>
          <p style={{ fontSize: "20px", fontWeight: 500 }}>
            {sumWithdrawal}{" "}
            <span style={{ fontSize: "12px", fontWeight: "normal" }}>USDT</span>
          </p>
          <p
            style={{
              fontSize: "10px",
              color: "#605e5e",
              fontWeight: "normal",
            }}
          >
            Total Withdraw
          </p>
        </div>
      </div>
    </Widget>
  );
};

export default TransactionHistory;
