import { Tooltip } from "antd";
import dayjs from "dayjs";

const limitString = 10;

export const sortOptions = [
  {
    value: "Latest First",
    label: "Latest First",
  },
  {
    value: "Earliest First",
    label: "Earliest First",
  },
];

export const transactionColumns = [
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    width: "30%",
    align: "center",
    render: (text) => (
      <p className="text-sm">{dayjs(text).format("DD MMM YYYY")}</p>
    ),
  },
  {
    title: "Description",
    dataIndex: "type",
    key: "type",
    width: "45%",
    align: "center",
    render: (text) => <p className="text-sm">{text}</p>,
  },
  {
    title: "Amount (USDT)",
    dataIndex: "amount",
    key: "amount",
    width: "25%",
    align: "center",
    render: (text) => <p className="text-sm">{text}</p>,
  },
  // {
  //   title: "Source",
  //   dataIndex: "id",
  //   key: "id",
  //   render: (text) => {
  //     return (
  //       <>
  //         {window.innerWidth > 430 ? (
  //           <p className="text-sm">{text}</p>
  //         ) : (
  //           <Tooltip title={text}>
  //             <p className="text-sm">{text.slice(0, limitString) + "..."}</p>
  //           </Tooltip>
  //         )}
  //       </>
  //     );
  //   },
  // },
];

export const transactionData = [
  {
    key: 1,
    date: "2022-01-26",
    type: "Deposit",
    amount: "18,000",
    source: "Selft",
  },
  {
    key: 2,
    date: "2022-01-25",
    type: "Stake Profit",
    amount: "3.25",
    source: "S2701220172",
  },
  {
    key: 3,
    date: "2022-01-28",
    type: "Withdraw",
    amount: "105",
    source: "Selft",
  },
  {
    key: 4,
    date: "2022-01-30",
    type: "Stake Profit",
    amount: "3.25",
    source: "S2701220172",
  },
  {
    key: 5,
    date: "2022-01-29",
    type: "Stake Capital",
    amount: "1.015",
    source: "S2701220168",
  },
  {
    key: 6,
    date: "2022-01-29",
    type: "Commission",
    amount: "3.25",
    source: "User A369",
  },
  {
    key: 7,
    date: "2022-01-27",
    type: "Stake Profit",
    amount: "13.95",
    source: "S2701220168",
  },
  {
    key: 8,
    date: "2022-01-33",
    type: "Stake Profit",
    amount: "3.25",
    source: "S2701220172",
  },
  {
    key: 9,
    date: "2022-02-06",
    type: "Stake Profit",
    amount: "3.25",
    source: "S2701220172",
  },
  {
    key: 10,
    date: "2022-02-06",
    type: "Stake Profit",
    amount: "3.25",
    source: "S2701220172",
  },
  {
    key: 11,
    date: "2022-02-03",
    type: "Stake Profit",
    amount: "3.25",
    source: "S2701220172",
  },
  {
    key: 12,
    date: "2022-02-06",
    type: "Stake Profit",
    amount: "3.25",
    source: "S2701220172",
  },
  {
    key: 13,
    date: "2022-01-05",
    type: "Stake Profit",
    amount: "3.25",
    source: "S2701220172",
  },
  {
    key: 14,
    date: "2022-02-01",
    type: "Stake Profit",
    amount: "3.25",
    source: "S2701220172",
  },
  {
    key: 15,
    date: "2022-02-07",
    type: "Stake Profit",
    amount: "3.25",
    source: "S2701220172",
  },
  {
    key: 16,
    date: "2022-01-27",
    type: "Stake",
    amount: "18,000",
    source: "Selft",
  },
  {
    key: 17,
    date: "2022-02-07",
    type: "Stake Profit",
    amount: "3.25",
    source: "S2701220172",
  },
  {
    key: 18,
    date: "2022-02-1",
    type: "Stake Profit",
    amount: "3.25",
    source: "S2701220172",
  },
  {
    key: 19,
    date: "2022-01-11",
    type: "Stake Profit",
    amount: "3.25",
    source: "S2701220172",
  },
  {
    key: 20,
    date: "2022-01-12",
    type: "Stake Profit",
    amount: "3.25",
    source: "S2701220172",
  },
  {
    key: 21,
    date: "2022-02-13",
    type: "Stake Profit",
    amount: "3.25",
    source: "S2701220172",
  },
  {
    key: 22,
    date: "2022-02-14",
    type: "Stake Profit",
    amount: "3.25",
    source: "S2701220172",
  },
];
